var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"w-100 d-flex"},[_c('div',{staticClass:"mt-auto mb-2"},[(_vm.title)?[_c('Heading',{attrs:{"size":4}},[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.titleTip)?_c('TipIcon',{attrs:{"tip":_vm.titleTip}}):_vm._e()],1)]:_vm._e()],2),_c('button',{staticClass:"btn btn-dark ml-auto mt-auto mb-2",attrs:{"data-cy":"create-element-btn"},on:{"click":function($event){return _vm.addAsset($event)}}},[_c('Icon',{attrs:{"name":"plus"}}),_vm._v(" "+_vm._s(_vm.translate('assets.label.element'))+" ")],1)]),_c('Divider'),_c('div',{staticClass:"bg-dark border-bottom border-secondary d-flex p-2"},[_c('div',{staticClass:"mx-auto"}),_c('Pagination',{staticClass:"ml-auto my-auto",attrs:{"page":_vm.pagination.page,"limit":_vm.pagination.limit,"rows":_vm.assets.length,"size":"sm"},on:{"change-page":function($event){_vm.pagination.page = $event},"change-limit":function($event){_vm.pagination.limit = $event}}})],1),_c('b-table',{attrs:{"items":_vm.assets,"fields":_vm.displayFields,"borderless":"","hover":"","busy":_vm.loading,"sort-by":_vm.sortProp,"sort-desc":_vm.sortDesc,"show-empty":"","table-variant":"dark","no-sort-reset":"","current-page":_vm.pagination.page,"per-page":_vm.pagination.limit,"thead-class":"border-bottom border-secondary"},on:{"sort-changed":_vm.onSortChanged},scopedSlots:_vm._u([_vm._l((['type', 'name', 'lastModified']),function(prop){return {key:("head(" + prop + ")"),fn:function(ref){
var label = ref.label;
return [_c('span',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(_vm.translate(("label." + prop)))+" "),(_vm.sortProp === prop)?_c('span',{staticClass:"my-auto ml-2"},[(_vm.sortDesc)?_c('Icon',{attrs:{"name":"sort-descending"}}):_c('Icon',{attrs:{"name":"sort-ascending"}})],1):_c('span',{staticClass:"ml-2 my-auto"},[_c('Icon',{staticStyle:{"opacity":"0"},attrs:{"name":"sort"}})],1)])]}}}),{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-light my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center text-light p-1"},[_c('small',{staticClass:"font-weight-light"},[_vm._v(" "+_vm._s(_vm.translate('assets.message.noExistingElements'))+" ")])])]},proxy:true},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('AbsBox',{staticClass:"d-flex",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openAsset(item)}}},[_c('span',{staticClass:"my-auto font-weight-light"},[_vm._v(" "+_vm._s(item.name)+" ")])])]}},{key:"cell(type)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"p-0"},[_c('div',{staticClass:"m-auto d-flex",staticStyle:{"width":"3em","height":"3em"}},[_c('Icon',{staticClass:"m-auto",style:(("color:" + (_vm.getTypeColor(item.type)))),attrs:{"name":_vm.getTypeIcon(item.type)}})],1)])]}},{key:"cell(lastModified)",fn:function(ref){
var item = ref.item;
return [_c('AbsBox',{staticClass:"d-flex"},[_c('small',{staticClass:"my-auto",staticStyle:{"font-size":"0.6em"}},[_c('DateLabel',{attrs:{"date":item.lastModified,"short":""}})],1)])]}},{key:"head(tags)",fn:function(){return [_vm._v(" "+_vm._s(_vm.translate('tags.label.tags'))+" ")]},proxy:true},{key:"cell(tags)",fn:function(ref){
var item = ref.item;
return [_c('AbsBox',{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"d-flex my-auto flex-wrap"},_vm._l((_vm.getAssetTags(item._id)),function(tag){return _c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
              title: tag.name,
              interactive: false,
              delay: 0,
              animation: false,
            }),expression:"{\n              title: tag.name,\n              interactive: false,\n              delay: 0,\n              animation: false,\n            }"}],key:tag._id,staticClass:"rounded",staticStyle:{"width":"1em","height":"1em","margin":"0.1em"},style:(("background:" + (tag.color)))})}),0)])]}},{key:"cell(controls)",fn:function(ref){
            var item = ref.item;
return [_c('AbsBox',{staticClass:"d-flex context-wrapper",class:{ hovered: _vm.draggedItem === item._id }},[_c('ContextButton',{staticClass:"m-auto",attrs:{"type":"vertical"},on:{"click":function (e) { return _vm.openContext(e, item); }}})],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }