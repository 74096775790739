












import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  props: {
    initialValue: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  setup(props, ctx) {
    const value = ref(props.initialValue);
    const changed = ref(false);

    const onChange = (event: any) => {
      changed.value = true;
      value.value = event.target.value;
    };

    const confirm = () => {
      changed.value = false;
      ctx.emit('confirm-action', { id: props.id, value: value.value });
    };

    const deleteAction = () => {
      ctx.emit('delete-action', props.id);
    };

    return { value, changed, confirm, deleteAction, onChange };
  },
});
