import { defineComponent } from '@vue/composition-api';
import { AbsBox, Container, Icon } from '@ui';

export const ConfirmBox = defineComponent({
  emits: ['confirm'],
  props: {
    title: { type: String, default: '' },
    buttonText: { type: String, default: '' },
    variant: { type: String, default: 'dark' },
  },
  render() {
    return (
      <AbsBox class="bg-light d-flex">
        <SlimContainer class="mt-5">
          <BoxWrapper>
            <div
              class="p-3"
              style={{
                display: 'flex',
                gap: '1rem',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <StartHeading class="my-auto" text={this.title} />
              <ConfirmButton
                label={this.buttonText}
                v-on:click={() => this.$emit('confirm')}
                class="my-auto"
                variant={this.variant}
                data-cy="confirm-btn"
              />
            </div>
          </BoxWrapper>
        </SlimContainer>
      </AbsBox>
    );
  },
});

const SlimContainer = defineComponent({
  render() {
    return (
      <Container class="my-auto">
        <div class="row">
          <div class="offset-md-2 col-md-8">{this.$slots.default}</div>
        </div>
      </Container>
    );
  },
});

const StartHeading = defineComponent({
  props: ['text'],
  render() {
    return (
      <h1 class="font-weight-bold" style="letter-spacing:0.1em">
        {this.text}
      </h1>
    );
  },
});

const BoxWrapper = defineComponent({
  render() {
    return (
      <div class="rounded-lg" style="border:1px solid rgba(0,0,0,0.1)">
        {this.$slots.default}
      </div>
    );
  },
});

const ConfirmButton = defineComponent({
  emits: ['click'],
  props: ['label', 'variant', 'route'],
  render() {
    if (this.route) {
      return <router-link to={this.route}>{this.label}</router-link>;
    }
    return (
      <button
        v-on:click={(e: Event) => this.$emit('click', e)}
        class={`btn btn-lg btn-outline-${this.variant} rounded-lg font-weight-bold`}
      >
        <Icon name="arrow-right-thick" /> {this.label.toUpperCase()}
      </button>
    );
  },
});
