import { NodeTemplate } from '@/components.edit/edit-graph';
import { AssetPointer } from '@/psychlab/types';

export type ExternalParams = {
  asset: AssetPointer<any> | null;
};

export const External: NodeTemplate<ExternalParams> = {
  name: 'External',
  icon: 'link text-black-50',
  parameters: {
    asset: null,
  },
  ports: {
    start: {
      type: 'input',
      label: {
        text: '',
        attributes: {
          x: 10,
        },
      },
      x: -50,
    },
    exit: {
      type: 'output',
      label: {
        text: '',
        style: {
          'text-anchor': 'end',
        },
        attributes: {
          x: -10,
        },
      },
      x: 50,
    },
  },
  template: `
	<svg style="overflow:visible" x="-50" y="-50" data-cy="graph-external-node">
		<rect rx="40" ry="40" width="100" height="100" stroke-width="1" stroke="white" fill="#92E8FE" ></rect>
	</svg>`,
};
