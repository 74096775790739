
















import { defineComponent, computed, ref, PropType } from "@vue/composition-api";
import { ItemBox, getBaseProps } from "../shared";

export default defineComponent({
	emits:[ "input" ],
	props:{
		value:{
			type:[String, Number],
			default:"",
		},
		...getBaseProps(),
	},
	components:{
		ItemBox,
	},
	setup(props, ctx){

		const val = computed({
			get:() => props.value,
			set:v => ctx.emit("input", v)
		});

		const rows = computed(() => {
			return 4;
		});

		return {
			val,
			rows,
		};
	}
});

