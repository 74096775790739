import { defineComponent, computed } from '@vue/composition-api';
import { GlobalAdd } from './GlobalAdd';
import { LangToggle } from '@lang';
import { HelpButton } from '@help';
import { UserButton } from '@user';
import { AbsBox, Icon } from '@ui';
import { PortalTarget } from 'portal-vue';

export const Navbar = defineComponent({
  props: {
    menuState: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, ctx) {
    const open = computed({
      get: () => props.menuState,
      set: () => ctx.emit('open-menu'),
    });

    return { open };
  },
  render() {
    return (
      <div
        class="bg-dark d-flex w-100 border-bottom border-secondary"
        style="height:72px;min-height:72px;position:relative;"
      >
        <ToggleButton v-model={this.open} />

        <FillBox>
          <PortalContent />
        </FillBox>

        <Divider />

        <ButtonBox>
          <GlobalAdd style="margin:auto 0.25em;" />
        </ButtonBox>

        <Divider />

        <ButtonBox>
          <HelpButton style="margin:auto 0.25em;" />
          <LangToggle style="margin:auto 0.25em;" />
          <UserButton style="margin:auto 0.25em;" />
        </ButtonBox>
      </div>
    );
  },
});

const Divider = defineComponent({
  template: `<div class="border-right h-100 border-secondary"/>`,
});

const FillBox = defineComponent({
  components: { AbsBox },
  template: `
	<div class="flex-fill" style="position:relative">
		<AbsBox>
			<slot/>
		</AbsBox>
	</div>
	`,
});

const ToggleButton = defineComponent({
  props: ['value'],
  components: { Icon },
  template: `
	<button
	v-if="!value"
	v-on:click="$emit('input', !value)"
	style="font-size:1.5rem;"
	class="btn btn-outline-dark  border-0 text-light rounded-0 shadow-none"
	>
		<Icon name="menu" class="m-auto"/>
	</button>
	`,
});

const PortalContent = defineComponent({
  components: { PortalTarget },
  template: `<PortalTarget name="context-header" data-cy="navbar-title" class="w-100 h-100"/>`,
});

const ButtonBox = defineComponent({
  template: `<div style="padding:0 0.5em; display:flex;"><slot/></div>`,
});
