









































































import {
  defineComponent,
  ref,
  computed,
  PropType,
  watch,
  reactive,
  inject,
  ComputedRef,
} from '@vue/composition-api';
import * as SessionAPI from '@/services/api/sessions';
import { useTranslate } from '@lang';
import { generateGUID } from '@utils/guid';
import { SessionContext } from '@components/sessions';
import { AbsBox, AlertBox, Container, Row, Col, Icon } from '@ui';
import {
  FormFooter,
  FormButton,
  RenderBackground as RenderFormBG,
  getThemeDefaults,
} from '@form-ui';
import { Form, FormItem } from '@psychlab/types/form';
import { ResponseData } from './ResponseData';
import { default as RunFormItem } from './run-form-item.vue';
import ExternalCodeDisplay from '@components/sessions/open-session/external-code-display.vue';

type DisplayItem = {
  key: string;
  section: string;
  config: FormItem<any>;
};

type FormItemComponent = {
  isValid?: () => boolean;
  getChannels?: (prefix: string) => ResponseData<any>[];
};

export default defineComponent({
  props: {
    data: {
      type: Object as PropType<Form>,
      required: true,
    },
    dataNamespace: {
      type: String,
      required: true,
    },
    showBack: {
      type: Boolean,
      default: false,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ExternalCodeDisplay,
    RunFormItem,
    AlertBox,
    AbsBox,
    Container,
    Row,
    Col,
    Icon,
    FormFooter,
    FormButton,
    RenderFormBG,
  },
  setup(props, context) {
    const getSessionContext = inject<() => SessionContext>('getSessionContext');

    if (!getSessionContext) {
      throw Error('Run Form: Session context missing!');
    }

    const { isMobile } = getSessionContext();

    const formItems = ref<FormItemComponent[]>([]);

    const buttonKeys = reactive({
      back: generateGUID(),
      next: generateGUID(),
    });

    const containsErrors = ref(false);

    const theme = computed(() => {
      const profiles = props.data.themeProfiles || [];
      if (!profiles.length) {
        return getThemeDefaults();
      }
      const p = profiles.find(tp => tp.id === props.data.defaultThemeProfile);
      return p ? p.theme : profiles[0].theme;
    });

    const loading = ref(false);
    const sectionIndex = ref(0);

    const canGoBack = computed(() => props.showBack || sectionIndex.value > 0);

    const cache = computed(() => context.root.$store.state.cache['storage']);

    const sectionOrder = computed(() => {
      if (props.data.sectionOrder) {
        return props.data.sectionOrder;
      }
      return props.data.sections.map(s => s.id);
    });

    const isEmptyForm = computed(() => {
      return (props.data as any)['asset'] === null;
    });

    const onLast = computed(() => {
      if (isEmptyForm.value) {
        return props.isLast;
      }
      return sectionIndex.value + 1 === sectionOrder.value.length && props.isLast;
    });

    const sessionId = computed(() => context.root.$route.params['sessionId']);

    const setProgressStep: any = inject('setProgressStep');

    const showProgressBar = inject<ComputedRef<boolean>>('showProgressBar');

    const refreshId = computed(() => {
      return currentSection.value ? currentSection.value.id : generateGUID();
    });

    const displayItems = computed(() => {
      if (!props.data) {
        return [];
      }
      const items: DisplayItem[] = [];
      sectionOrder.value.forEach(sectionId => {
        const s = props.data.sections.find(s => s.id === sectionId);
        if (!s) {
          return;
        }

        const itemOrder = s.itemOrder || s.items.map(it => it.id);

        itemOrder.forEach(itemId => {
          const c = s.items.find(it => it.id === itemId);
          if (!c) {
            return;
          }
          items.push({
            key: `${props.dataNamespace}/${itemId}`,
            section: sectionId,
            config: c,
          });
        });
      });
      return items;
    });

    const showItem = (item: FormItem<any>) => {
      if (!item.platforms) {
        return true;
      }
      if (!item.platforms.length) {
        return true;
      }
      const current = isMobile.value ? 'mobile' : 'desktop';
      return item.platforms.indexOf(current) >= 0;
    };

    const refreshProgress = () => {
      if (sectionIndex.value < 0) {
        return;
      }
      if (isEmptyForm.value) {
        return;
      }
      const s = sectionOrder.value[sectionIndex.value];
      if (!s) {
        return;
      }
      setProgressStep(`${props.dataNamespace}/${s}`);
    };

    const currentSection = computed(() => {
      if (sectionOrder.value.length === 0 || sectionIndex.value < 0) {
        return null;
      }
      const id = sectionOrder.value[sectionIndex.value];
      return props.data.sections.find(s => s.id === id);
    });

    // cache page index
    watch(sectionIndex, i => {
      cache.value[`${props.dataNamespace}.index`] = i;
      SessionAPI.cacheSessionValue(sessionId.value, `${props.dataNamespace}.index`, i);
      refreshProgress();
    });

    const { translate } = useTranslate();

    const onItemTouched = (e: { id: string }) => {
      containsErrors.value = false;
    };

    const saveData = async (channels: ResponseData<any>[]) => {
      let success = true;

      const unsaved = channels.filter(ch => {
        const cached = cache.value[`${ch.key}.saved`];
        if (cached === undefined) {
          return true;
        }
        if (cached === ch.data) {
          return false;
        }
        return true;
      });

      if (unsaved.length === 0) {
        return true;
      }

      loading.value = true;
      let error = true;
      try {
        const result = await SessionAPI.saveDataChannels(sessionId.value, unsaved);
        for (var i = 0; i < result.length; i++) {
          const success = result[i];
          const c = unsaved[i];
          if (success) {
            cache.value[`${c.key}.saved`] = c.data;
            if (sessionId.value) {
              SessionAPI.cacheSessionValue(sessionId.value, `${c.key}.saved`, c.data);
            }
          }
        }
        success = result.findIndex(s => !s) < 0;
      } catch (err) {
        console.error(err);
        error = false;
        success = false;
      }
      loading.value = false;
      return success;
    };

    const regenButtonKeys = () => {
      buttonKeys.back = generateGUID();
      buttonKeys.next = generateGUID();
    };

    const next = async () => {
      let channels: ResponseData<any>[] = [];
      const items = formItems.value;

      for (var i = 0; i < items.length; i++) {
        const fn = items[i].isValid;
        if (fn && !fn()) {
          containsErrors.value = true;
          return;
        }
      }

      for (var i = 0; i < items.length; i++) {
        const fn = items[i].getChannels;
        if (fn) {
          fn(props.dataNamespace).forEach(c => channels.push(c));
        }
      }

      if (channels.length > 0) {
        if (!(await saveData(channels))) {
          return;
        }
      }
      const ni = sectionIndex.value + 1;
      const solen = (!isEmptyForm.value ? sectionOrder.value : []).length;

      if (ni < solen) {
        sectionIndex.value = ni;
      } else {
        context.emit('end');
      }
      regenButtonKeys();
    };

    const back = () => {
      containsErrors.value = false;
      const i = sectionIndex.value - 1;
      if (i < 0) {
        context.emit('back');
      } else {
        sectionIndex.value = i;
      }

      regenButtonKeys();
    };

    const init = () => {
      const sc = SessionAPI.getSessionCache(sessionId.value);
      if (sc) {
        Object.keys(sc).forEach(k =>
          context.root.$store.commit('cache/setValue', { k, v: sc[k] }),
        );
      }
      const v = cache.value[`${props.dataNamespace}.index`];
      if (v) {
        sectionIndex.value = v;
      }

      refreshProgress();
    };

    init();

    return {
      sectionOrder,
      formItems,
      loading,
      sectionIndex,
      canGoBack,
      cache,
      onLast,
      displayItems,
      currentSection,
      buttonKeys,
      containsErrors,
      showProgressBar,
      refreshId,
      isEmptyForm,
      theme,
      translate,
      showItem,
      next,
      back,
      onItemTouched,
    };
  },
});
