























































import { defineComponent, computed, ref, PropType, set } from '@vue/composition-api';
import { Draggable, AbsBox, Icon, openContextMenu, ContextOption } from '@ui';
import { useTranslate } from '@lang';
import { Form, FormItem, FormSection } from '@psychlab/types/form';
import { default as FoldButton } from './fold-btn.vue';
import { default as LabelButton } from './label-btn.vue';
import { default as DragButton } from './drag-btn.vue';
import { default as PlusButton } from './plus-btn.vue';
import { default as ContextButton } from './ctx-btn.vue';

import { editText } from '@/AppModal';

import { useFormIcons } from '@form-ui';

const sectionDrag = {
  animation: 0,
  disabled: false,
  ghostClass: 'ghost',
  handle: '.section-drag-btn',
};

const itemDrag = {
  animation: 0,
  disabled: false,
  ghostClass: 'ghost',
  handle: '.item-drag-btn',
  group: 'items',
};

export default defineComponent({
  props: {
    value: {
      type: String,
      default: '',
    },
    // activeSection:{
    // 	type:String,
    // 	default:"",
    // },
    // activeId:{
    // 	type:String,
    // 	default:"",
    // },
    data: {
      type: Object as PropType<Form>,
      required: true,
    },
  },
  components: {
    FoldButton,
    LabelButton,
    DragButton,
    PlusButton,
    ContextButton,
    AbsBox,
    Icon,
    Draggable,
  },
  setup(props, ctx) {
    const { translate } = useTranslate();

    const { getItemIcon } = useFormIcons();

    const activeId = computed({
      get: () => props.value,
      set: v => ctx.emit('input', v),
    });

    const foldouts = ref<{ [k: string]: boolean }>({});

    const getSectionTitle = (s: FormSection) => {
      return s.label || `(${translate('label.untitled').toLowerCase()})`;
    };

    const getItemTitle = (it: FormItem<any>) => {
      return it.name || `(${translate('label.untitled').toLowerCase()})`;
    };

    const isActive = (type: 's' | 'i', id: string) => {
      return activeId.value === `${type}:${id}`;
      // return props.activeSection === id;
      // return type === "s" ? props.activeSection === id : props.activeItem === id;
    };

    const selectSection = (sid: string) => {
      activeId.value = `s:${sid}`;
      // ctx.emit("select-section", sid);
    };

    const selectItem = (iid: string) => {
      activeId.value = `i:${iid}`;
    };

    const setFold = (sid: string, e: boolean) => {
      set(foldouts.value, sid, e);
    };

    const renameSection = (sid: string) => {
      const s = props.data.sections.find(fs => fs.id === sid);
      if (!s) {
        return;
      }
      editText(
        {
          title: translate('forms.label.sectionName'),
          variant: 'light',
          value: s.label || '',
        },
        v => {
          set(s, 'label', v);
        },
      );
    };

    const openSectionContext = (sid: string, e: Event) => {
      const s = props.data.sections.find(fs => fs.id === sid);
      if (!s) {
        return;
      }

      const opts: ContextOption[] = [
        {
          name: translate('action.rename'),
          icon: 'mdi.pencil',
          order: -1,
          fn() {
            renameSection(sid);
          },
        },
        {
          name: translate('action.duplicate'),
          icon: 'mdi.content-duplicate',

          fn() {
            ctx.emit('duplicate-section', sid);
          },
        },
        {
          name: translate('action.delete'),
          icon: 'mdi.trash-can',
          order: 2,
          fn() {
            ctx.emit('delete-section', sid);
          },
        },
      ];

      openContextMenu(e, opts);
    };

    const toggleList = () => {
      ctx.emit('toggle');
    };

    return {
      foldouts,
      activeId,
      isActive,
      setFold,
      translate,
      selectSection,
      getItemIcon,
      selectItem,
      sectionDrag,
      itemDrag,
      getItemTitle,
      renameSection,
      getSectionTitle,
      openSectionContext,
      toggleList,
    };
  },
});
