import { ref, SetupContext, watch } from "@vue/composition-api";
import * as SessionAPI from "@/services/api/sessions";
import * as StudyAPI from "@/services/api/studies";
import { Session } from "@/psychlab/types/sessions";
import { SessionLabel } from "@/psychlab/types/studies";
import * as Utils from "../utils";
import { useSessionRouteFilters } from "./useSessionRouteFilters";

type Dictionary<T> = { [k:string]:T };

export const useSessionList = (context:SetupContext, studyId: string) => {

	const sessions = ref<Session[]>([]);
	const labels = ref<SessionLabel[]>([]);
	const loading = ref(true);
	const rows = ref(0);
	const {
		sortKey,
		sortAsc,
		page,
		limit,
	} = initRouteVars(context);

	const {
		queryFilters,
	} = useSessionRouteFilters(studyId, context);

	let initialPage = page.value;

	const reloadWatchers:Function[] = [];


	const loadSessions = async () => {
		const r = await loadStudySessions(page.value, limit.value, queryFilters.value);
		rows.value = r.count;
		sessions.value = r.items;
	};

	const loadLabels = async () => {
		labels.value = await loadSessionLabels(studyId);
	};

	const loadData = async () => {
		loading.value = true;
		await Promise.all([loadSessions(), loadLabels()]);
		loading.value = false;
		for(let i = reloadWatchers.length - 1; i >= 0; i--){
			reloadWatchers[i]();
			reloadWatchers.splice(i, 1);
		}
	};

	const onSortChange = (nextSortKey: string) => {
		if (sortKey.value === nextSortKey) {
			sortAsc.value = !sortAsc.value;
		} else {
			sortKey.value = nextSortKey;
			sortAsc.value = true;
		}
	};

	const onPerPageChange = (value: number) => {
		limit.value = value;
	};

	const onPageChange = (p: number) => {
		page.value = p;
	};

	const onReloadData = async() => {
		await loadData();
		// reloadListeners.forEach(fn => fn());
	};

	const watchNextReload = (fn:() => void) => {
		reloadWatchers.push(fn);
	};

	watch(loading, v => {
		if(initialPage < 0 || v !== false){ return; }
		page.value = initialPage;
		initialPage = -1;
	})

	watch(page, (v) => {
		Utils.setQueryValue("page", v, context);
	});
	watch(limit, (v) => Utils.setQueryValue("limit", v, context));

	watch(rows, () => {
		const qv = Utils.getQueryValue("page", context);
		if(!qv){ return; }
		page.value = Number(qv);
	});

	watch([limit, page, queryFilters], () => {
		loadData();
	});

	loadData();

	return {
		sessions,
		page,
		rows,
		limit,
		sortKey,
		sortAsc,
		labels,
		loading,
		onSortChange,
		onPerPageChange,
		onPageChange,
		onReloadData,
		watchNextReload,
	};
};

const initRouteVars = (context:SetupContext) => {
	const sortKey = ref("created");
	const sortAsc = ref(false);
	const page = ref(1);
	const limit = ref(20);

	const sortqv = context.root.$route.query["sort"];
	if(sortqv && typeof(sortqv) === "string"){
		const s = sortqv.split("-");
		sortKey.value = s[s.length > 1 ? 1 : 0];
		sortAsc.value = s.length > 1 ? true : false;
	}

	const pageqv = context.root.$route.query["page"];
	if(pageqv && typeof(pageqv) === "string"){
		page.value = Number(pageqv);
	}

	const limitqv = context.root.$route.query["limit"];
	if(limitqv &&typeof(limitqv) === "string"){
		limit.value = Number(limitqv);
	}

	return {
		sortKey,
		sortAsc,
		page,
		limit,
	};
};

type SessionQueryResult = {
	items:Session[];
	count:number;
};

const sessionFields = [
	"completed",
	"created",
	"done",
	"duration",
	"expires",
	"group",
	"progress",
	"optionalProgress",
	"state",
	"labels",
	"log",
];

const loadStudySessions = async (page:number, limit:number, filters:Dictionary<any>):Promise<SessionQueryResult> => {
	try {
		const q: Dictionary<any> = {
			...{
				limit,
				page,
			},
			...filters,
			select: sessionFields.join(" "),
		};

		const { sessions, count } = await SessionAPI.loadSessions(q);
		return { items:sessions, count };
	} catch (error:any) {
		console.log(error.message);
	}
	return { items:[], count:0 };
};

const loadSessionLabels = async (studyId:string) => {
	return await StudyAPI.getStudyLabels(studyId);
};
