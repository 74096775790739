import { defineComponent, computed } from "@vue/composition-api";
import { useLang } from "@lang";
import { AbsBox } from "@ui";
import { isIOS } from "@utils/platform";

enum Translations {
	FacebookTitle = "session.message.facebookWarningTitle",
	FacebookInfo = "session.message.facebookWarningInfo",
	FacebookSwitch = "session.message.facebookWarningSwitch",
}

export const FacebookWarning = defineComponent({
	setup(_, context){

		const { translate } = useLang(context);

		const text = computed(() => ({
			title:translate(Translations.FacebookTitle),
			subtitle:translate(Translations.FacebookInfo),
			description:translate(Translations.FacebookSwitch),
		}))

		return { text };
	},
	render(){
		return (
			<WarningBox>
				<div class="mt-3 container" >
					<div class="row">
						<div class="col">
							<div class="text-light rounded">
								<div class="p-3">
									<h1 class="text-center font-weight-bold m-0">{this.text.title}</h1>
									<h5 class="text-center font-weight-light">{this.text.subtitle}</h5>
								</div>
								<div class="d-flex flex-column">
									<WarningImage/>
								</div>
								<div class="p-3">
									<h6 class="text-center font-weight-light m-0">{this.text.description}</h6>
								</div>
							</div>
						</div>
					</div>
				</div>
				<FBInstructions class="my-3"/>
			</WarningBox>
		);
	}
});

const WarningBox = defineComponent({
	props:{
		background:{ type:String, default:"#166fe5", }
	},
	render(){
		return (
			<AbsBox style={`overflow:auto;background:${this.background}`}>
				{this.$slots.default}
			</AbsBox>
		);
	}
});

const WarningImage = defineComponent({
	render(){
		return (
			<img src="/img/fb/fb_heart.png" class="m-auto" style="height:auto;width:2em;"/>
		)
	}
});

const getImages = (platform:string) => [1,2].map(v => `/img/fb/${platform}/${v}.png`);

const platform = isIOS() ? "ios" : "android";

const FBInstructions = defineComponent({
	render(){
		const dimages = getImages(platform).map((img,i) => (
			<div class="col" key={img+i}>
				<PreviewImage url={img}/>
			</div>
		));
		return (
			<div class="container">
				<div class="row">
					{dimages}
				</div>
			</div>
		);
	}
});

const PreviewImage = defineComponent({
	props:[ "url" ],
	render(){
		return (
			<div class="text-dark shadow" style="border:2px solid white !important">
				<img src={this.url} class="w-100"/>
			</div>
		);
	}
});
