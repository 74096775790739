











import { defineComponent, ref, computed, inject } from '@vue/composition-api';
import { useTranslate } from '@lang';
import { AbsBox, AlertBox, Container, Row, Col, Icon } from '@ui';
import { FormFooter, FormButton, RenderBackground as RenderFormBG } from '@form-ui';
import { getExternalCode } from '@/services/api/getExternalCode';
import { SessionContext } from '@components/sessions';

export default defineComponent({
  components: {
    AlertBox,
    AbsBox,
    Container,
    Row,
    Col,
    Icon,
    FormFooter,
    FormButton,
    RenderFormBG,
  },
  setup(_props, context) {
    const getSessionContext = inject<() => SessionContext>('getSessionContext');

    if (!getSessionContext) {
      throw Error('Run Form: Session context missing!');
    }

    const externalOneTimeCode = ref<null | string>(null);
    const sessionId = computed(() => context.root.$route.params['sessionId']);

    const { translate } = useTranslate();

    const fetchExternalCode = async () => {
      try {
        const externalCodeObj = await getExternalCode(sessionId.value);
        externalOneTimeCode.value = externalCodeObj.code;
      } catch (error) {
        console.error('Unable to fetch external code', error);
      }
    };

    const init = async () => {
      await fetchExternalCode();
    };

    init();

    return {
      externalOneTimeCode,
      translate,
    };
  },
});
