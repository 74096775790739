// utils
import { PropMap } from "../utils/PropMap";
import { EventBus } from "../utils/EventBus";
import { InputKeys } from "../utils/InputKeys";
import { ScrollGrid } from "../utils/ScrollGrid";
import { SnapOffsetDrag } from "../utils/SnapOffsetDrag";
import * as GraphUtils from "../utils/Graph";
import { createDOMSVG, createSnapSVG } from "../utils/DOM";
import { findSnap } from "../utils/Snap";
import { getGUID } from "../utils/Random";
import { Dictionary } from "../utils/Dictionary";
// entities
import { Node } from "./Node";
import { Port } from "./Port";
import { Transition } from "./Transition";
import { DragTransition } from "./DragTransition";
import { Selection, Selectable } from "./Selection";
// types
import {
	Vector2D,
	ViewportTransform,
	GraphData,
	NodeTemplate,
	TransitionValidator,
	NodeData,
	TransitionData
} from "../types";
// lib
import onChange from "on-change";
import * as SnapSVG from "snapsvg";

const computeZoomOpacity = (zoom:number) => {
	const f = (zoom - 0.1) / 2.1;
	const s = 0.35;
	return (1 - f) * s;
};

type EditorGraph = {
	nodes:Dictionary<Node>,
	transitions:Dictionary<Transition>
}

type EditorConfig = {
	transitionRules:TransitionValidator[],
	templates:NodeTemplate<any>[]
}

export class Editor {

	constructor(containerId:string, config:EditorConfig){

		this._inputKeys = new InputKeys();

		this._config = config;

		this._graph = onChange(this._graphData, (p) => {
			this._events.emit("dataChanged", this._graphData);
		});

		if(this._gridArea){
			this._gridArea.remove();
			this._gridArea = null;
		}

		this._snapContainer = findSnap(containerId);
		this._viewportContainer = this._snapContainer.node;

		this._gridArea = createSnapSVG(this._snapContainer);
		this._gridArea.click(e => this.clearSelection());

		window.addEventListener("mouseup", () => {
			setTimeout(() => this._dragTransition.clear(), 0);
		});



		this._gridArea.drag((dx, dy, mx, my, e) => {

			if(!this._dragSelection.element) { return; }


			let x = this._dragSelection.startX;
			let y = this._dragSelection.startY;

			let width = dx;
			let height = dy;
			let zs = (1.0 / this._viewportTransform.zoom);
			if(dx < 0){
				x += dx;
				width = Math.abs(dx);
			}
			if(dy < 0){
				y += dy;
				height = Math.abs(dy);
			}
			width *= zs;
			height *= zs;
			x *= zs;
			y *= zs;
			x -= this._viewportTransform.offsetX * zs;
			y -= this._viewportTransform.offsetY * zs;
			if(this._dragSelection.element){

				this._dragSelection.element.attr({ x, y, width, height })

				let bb = this._dragSelection.element.getBBox();
				if(bb.width > 0){
					this.selectBoundingBox(bb);
				}
			}


		},
		(mx, my, e:any) => {
			if(e.button !== 0){ return; }
			this._dragSelection.element = this._overlayArea.rect(0, 0, 0, 0);
			this._dragSelection.element.attr({
				fill: "rgba(189, 237, 255, 0.7)",
				stroke: "#1f87ff"
			});
			this._dragSelection.startX = e.layerX;
			this._dragSelection.startY = e.layerY;
		},
		(e) => {
			if(this._dragSelection.element){
				let bb = this._dragSelection.element.getBBox();
				if(bb.width > 0){
					this.selectBoundingBoxDelayed(bb, 0);
				}
				this._dragSelection.element.remove();
			}
			this._dragSelection.element = null;
		}
		);


		this._rootCanvas = createDOMSVG("EditorCanvas", this._viewportContainer);
		this._mainCanvas = findSnap("#" + this._rootCanvas.id);
		this._scrollGrid = new ScrollGrid(this._gridArea);
		this._transformGroup = this._mainCanvas.group();



		this._transitionArea = this._transformGroup.group();
		this._nodeArea = this._transformGroup.group();
		this._overlayArea = this._transformGroup.group();

		(this._transitionArea.node.style as any)["pointer-events"] = "all";
		(this._nodeArea.node.style as any)["pointer-events"] = "all";

		this._dragTransition = new DragTransition(this._mainCanvas, this._viewportTransform);

		const scope = this;
		this._inputKeys.on("Delete", this.onDelete.bind(this));



		this._scrollGrid.on("drag", (e) => {
			scope.setOffset(scope._viewportTransform.offsetX + e.x, scope._viewportTransform.offsetY + e.y);
			this._events.emit("draggedView", {
				x:this._viewportTransform.offsetX,
				y:this._viewportTransform.offsetY
			});
		});

		this._mainCanvas.mousemove(this.onCanvasMousemove.bind(this));
		this._gridArea.mousemove(this.onCanvasMousemove.bind(this));
		this._mainCanvas.mouseup(this.onCanvasMouseup.bind(this));
		this._gridArea.mouseup(this.onCanvasMouseup.bind(this));

		window.addEventListener("mouseup", () => this._draggedPorts = { a: null, b: null })

		this._canvasDrag = new SnapOffsetDrag(this._mainCanvas);
		this._canvasDrag.onDrag((e) => {

			this._viewportTransform.offsetX += e.x;
			this._viewportTransform.offsetY += e.y;
			this._scrollGrid.addOffset(e.x, e.y);
			this._transformGroup.attr({
				transform: this.getTransform()
			});

			this._events.emit("draggedView", {
				x:this._viewportTransform.offsetX,
				y:this._viewportTransform.offsetY
			});
		});

		this._canvasDrag.configure({
			"button": 4
		});

		this._props.initialize({
			"zoom": {
				value: 1,
				onChange: this.updateZoom.bind(this)
			}
		});
		this._props.useDefaultValues();
	}

	public configure(ob:{ [k:string]:any }){
		this._props.configure(ob);
	}

	public onSelectionChange(fn:any){
		this._selection.onChange(fn);
	};

	public loadGraph(gData:GraphData){

		this.clearCanvas();
		this._canvasData = {
			nodes: {},
			transitions: {}
		};

		if(!GraphUtils.validateGraphData(gData)){ return; }

		Object.keys(gData.nodes).forEach(k => this.addCanvasNode(k, gData.nodes[k]))
		Object.keys(gData.transitions).forEach(k => this.addCanvasTransition(k, gData.transitions[k]))

		this._graphData = gData;

		const scope = this;

		this._graph = onChange(this._graphData, (p) => {
			scope._events.emit("dataChanged", scope._graphData);
		});
	};

	public init(){

	}

	public createNode(n:NodeData){
		const id = getGUID();
		this._graph.nodes[id] = n;
		this.addCanvasNode(id, n);
	};

	public deleteNode(id:string){
		this.deleteNodeID(id);
	}

	public getScrollOffset(){
		return { x: this._viewportTransform.offsetX, y: this._viewportTransform.offsetY };
	}

	public centerFirstNode(){
		for(var key in this._canvasData.nodes){
			this.centerOnPoint(this._canvasData.nodes[key].getX(), this._canvasData.nodes[key].getY());
			break;
		}
	};

	// public setNodeLabel(id:string, text:string){
	// 	let n = this._canvasData.nodes[id];
	// 	if(!n){ return; }
	// 	n.setLabelText(text);
	// };

	public on<N extends keyof EditorEvents>(name:N, fn:EditorEvents[N]){
		this._events.on(name, fn);
	}

	public off<N extends keyof EditorEvents>(name:N, fn:EditorEvents[N]){
		this._events.off(name, fn);
	}

	public dispose(){
		this._inputKeys.dispose();
	}

	private _config:EditorConfig;
	private _selection:Selection = new Selection();
	private _props:PropMap = new PropMap();
	private _draggedPorts:{
		a:Port|null|undefined,
		b:Port|null|undefined
	}|null = null;

	private _inputKeys:InputKeys;
	private _events = new EventBus<EditorEvents>();
	private _mousePos:Vector2D = { x: 0, y: 0 };
	private _viewportTransform:ViewportTransform = {
		offsetX: 0,
		offsetY: 0,
		zoom: 1
	};
	private _snapContainer:SnapSVG.Paper;
	private _viewportContainer:HTMLElement|null = null;
	private _gridArea:SnapSVG.Paper|null = null;
	private _rootCanvas:any =  null;
	private _mainCanvas:SnapSVG.Paper;
	private _transformGroup:SnapSVG.Paper;
	private _scrollGrid:ScrollGrid;
	private _transitionArea:SnapSVG.Paper;
	private _nodeArea:SnapSVG.Paper;
	private _overlayArea:SnapSVG.Paper;
	private _canvasDrag:SnapOffsetDrag;
	private _dragTransition:DragTransition;

	private _dragSelection = {
		element: null as any,
		startX: 0,
		startY: 0
	}

	private _graphData:GraphData = {
		nodes:{},
		transitions: {},
		startNode:null
	};

	private _graph:GraphData;

	private _canvasData:EditorGraph = {
		nodes: {},
		transitions: {}
	};

	private clearCanvas(){
		Object.keys(this._canvasData.nodes).forEach(k => this._canvasData.nodes[k].destroy());
		Object.keys(this._canvasData.transitions).forEach(k => this._canvasData.transitions[k].destroy());
	};



	private getTransform(){
		return GraphUtils.formatTransform(this._viewportTransform);
	};

	private getCurrentCenterPoint(){
		if(!this._gridArea){ return { x:0, y:0 }; }
		return GraphUtils.getTransformCenter(this._gridArea.node, this._viewportTransform)
	};

	private centerOnPoint(x:number, y:number){
		if(!this._gridArea){ return; }
		let startx = -x * this._viewportTransform.zoom;
		let starty = -y * this._viewportTransform.zoom;
		let ox = startx + this._gridArea.node.scrollWidth * 0.5;
		let oy = starty + this._gridArea.node.scrollHeight * 0.5;
		this.setOffset(ox, oy);
		let center = this.getCurrentCenterPoint();
	};

	private onCanvasDrag(e:any){
		this._viewportTransform.offsetX += e.x;
		this._viewportTransform.offsetY += e.y;
		this._scrollGrid.addOffset(e.x, e.y);
		this._transformGroup.attr({
			transform: this.getTransform()
		});
	}

	private onCanvasMousemove(e:any){

		const position = this._mainCanvas.node.getBoundingClientRect();
		const x = position.x;
		const y = position.y;
		this._mousePos.x = e.clientX - x;
		this._mousePos.y = e.clientY - y;

		const isCanvasElement = true;

		if(this._draggedPorts && isCanvasElement){
			let pos = this._draggedPorts.b ? this.computeScaledPortPosition(this._draggedPorts.b) : this._mousePos;
			this._dragTransition.setEnd(pos.x, pos.y);
		}
	};

	private onCanvasMouseup(e:any){
		this._dragTransition.clear();
	};

	private onDelete(e:any){
		this._selection.getItems().forEach(item => {
			this._selection.remove(item);
			if(Object.getPrototypeOf(item) === Node.prototype){
				const id = (item as Node).getID();
				this.deleteNode(id);
			}
			if(Object.getPrototypeOf(item) === Transition.prototype){
				this.deleteTransition(item as any);
			}
		});
	};

	private updateZoom(v:number){
		let currentCenter = this.getCurrentCenterPoint();
		this._scrollGrid.configure({
			zoom: v,
			opacity: computeZoomOpacity(v)
		});
		this._viewportTransform.zoom = v;
		this._transformGroup.attr({
			transform: this.getTransform()
		});
		this.centerOnPoint(currentCenter.x, currentCenter.y);
	};

	private findTemplate(name:string){
		return this._config.templates.find(t => t.name === name);
	};

	private validatePortConnection(portA:Port, portB:Port){
		return GraphUtils.validateTransition(portA, portB, this._config.transitionRules);
	};

	private clearSelection(){
		this._selection.clear();
	};

	private onNodePressed(node:Node){
		let ctrl = this._inputKeys.isDown("Control");
		if(!ctrl){
			this.clearSelection();
			this._selection.add(node);
			return;
		}
		if(ctrl){
			if(!this._selection.isSelected(node)){
				this._selection.add(node);
			}else{
				this._selection.remove(node);
			}
			return;
		}
	};

	private onNodeReleased(node:Node){}

	private onTransitionPressed(transition:Transition){
		if(!this._inputKeys.isDown("Control")){
			this.clearSelection();
		}
		this._selection.add(transition);
	};

	private findNodeTransitions(predicate:(t:Transition) => boolean){
		return Object.keys(this._canvasData.transitions)
		.map(k => this._canvasData.transitions[k])
		.filter(predicate);
	}

	private deleteTransition(t:Transition){
		this.deleteTransitionID(t.getID());
	};

	private deleteTransitionID(id:string){
		const t = this._canvasData.transitions[id];
		if(!t){ return; }
		delete this._canvasData.transitions[id];
		t.destroy();
		delete this._graph.transitions[id];
	}

	private deleteNodeID(id:string){
		let node = this._canvasData.nodes[id];
		let transitions = this.findNodeTransitions(t => t.hasNodeConnection(id));
		transitions.forEach(t => this.deleteTransition(t));
		node.destroy();
		delete this._graph.nodes[id];
	}

	// private deleteNode(node:Node){
	// 	this.deleteNodeID(node.getID());
	// }

	private formTransition(portA:Port, portB:Port){
		if(!portA || !portB) { return; }
		if(!this.validatePortConnection(portA, portB)){ return; }
		let tdata = {
			path: `${portA.getPath()},${portB.getPath()}`
		};
		let id = getGUID();
		this._graph.transitions[id] = tdata;
		this.addCanvasTransition(id, tdata);
	};

	private computeScaledPortPosition(port:Port){
		var z = this._viewportTransform.zoom;
		let x = (port.getGlobalX());
		let y = (port.getGlobalY());
		x = x * z;
		y = y * z;
		x += this._viewportTransform.offsetX;
		y += this._viewportTransform.offsetY;
		return { x, y };
	};

	private portPressed(port:Port){
		this._draggedPorts = {
			a: port,
			b: undefined
		};
		const pos = this.computeScaledPortPosition(port);
		this._dragTransition.setStart(pos.x, pos.y);
		this._dragTransition.setEnd(pos.x, pos.y);
	};

	private portReleased(port:Port){
		if(this._draggedPorts && this._draggedPorts.a && this._draggedPorts.b){
			this.formTransition(this._draggedPorts.a, this._draggedPorts.b);
			this._draggedPorts = null;
		}
		this._dragTransition.clear();
	};

	private portHovered(port:Port){

		if(this._draggedPorts && this._draggedPorts.a){
			if(this._draggedPorts.a && this._draggedPorts.a !== port){
				this._draggedPorts.b = port;
				if(!this.validatePortConnection(this._draggedPorts.a, this._draggedPorts.b)){
					this._dragTransition.setError(true);
				}
				this._dragTransition.setComplete(true);
			}
		}
	}

	private portUnhovered(port:Port){
		if(this._draggedPorts){
			this._draggedPorts.b = undefined;
			this._dragTransition.setComplete(false);
		}
		this._dragTransition.setError(false);
	}

	private addCanvasNode = (id:string, nData:NodeData) => {

		const eScope = this;

		const template = this.findTemplate(nData.type);

		if(!template){
			console.error(`Error adding node type '${nData.type}'`);
			return;
		}

		const n = new Node(id, this._nodeArea, template, this._viewportTransform, nData.parameters);

		if(this._selection.isSelected(n)){
			this._selection.removeID(id);
			this._selection.add(n);
		}

		n.setPosition(nData.x, nData.y);
		n.on("newPosition", (event) => {
			eScope._graph.nodes[id].x = event.x;
			eScope._graph.nodes[id].y = event.y;
		});
		n.on("press",e => {
			eScope.onNodePressed(n)
		});
		n.on("release", e => eScope.onNodeReleased(n));

		n.on("position-updated", (pos) => {
			this._events.emit("node-position-updated", { node:id, pos });
		})

		n.on("drag", e => {
			eScope._selection.forEachItem(item => item !== n && Object.getPrototypeOf(item) === Node.prototype, item => {
				item.addMovement(e.x, e.y);
			});
		});


		n.on("context", e => eScope.onNodeContext(n, e));
		n.on("dblclick", e => eScope.onNodeOpen(n));


		const portPressed = this.portPressed.bind(this);
		const portReleased = this.portReleased.bind(this);
		const portHovered = this.portHovered.bind(this);
		const portUnhovered = this.portUnhovered.bind(this);

		n.on("portPressed", portPressed);
		n.on("portReleased", portReleased);
		n.on("portHovered", portHovered);
		n.on("portUnhovered", portUnhovered);
		this._canvasData.nodes[id] = n;
	}



	private addCanvasTransition(id:string, tData:TransitionData){

		const eScope = this;
		let parsed = GraphUtils.parseTransitionPath(tData.path);
		if(!parsed) { return null; } // invalid path
		let nodea = this._canvasData.nodes[parsed.a.node];
		let nodeb = this._canvasData.nodes[parsed.b.node];
		if(!nodea || !nodeb) { return null; } // nodes not found
		let porta = nodea.getPort(parsed.a.port);
		let portb = nodeb.getPort(parsed.b.port);
		if(!porta || !portb) { return null; } // ports not found
		let transition = new Transition(id, this._transitionArea, porta, portb);
		transition.on("press", () => eScope.onTransitionPressed(transition));
		this._canvasData.transitions[id] = transition;
	}

	private findInsideBoundingBox(bbox:SnapSVG.BBox){
		let result:Selectable[] = [];
		for(var key in this._canvasData.nodes){
			if(this._canvasData.nodes[key].intersectsBBox(bbox)){
				result.push(this._canvasData.nodes[key]);
			}
		}
		for(var key in this._canvasData.transitions){
			if(this._canvasData.transitions[key].intersectsBBox(bbox)){
				result.push(this._canvasData.transitions[key]);
			}
		}
		return result;
	};

	private selectBoundingBoxDelayed(bbox:SnapSVG.BBox, delay:number){
		setTimeout(() => this.selectBoundingBox(bbox), delay);
	};

	private selectBoundingBox(bbox:SnapSVG.BBox){
		this.clearSelection();
		const insideRect = this.findInsideBoundingBox(bbox);
		insideRect.forEach(item => {
			this._selection.add(item)
		});
	};

	private setOffset(x:number, y:number){
		this._viewportTransform.offsetX = x;
		this._viewportTransform.offsetY = y;
		this._transformGroup.attr({
			transform: this.getTransform()
		});
		this._scrollGrid.setOffset(x, y);
	};

	private onNodeContext(n:Node, e:any){
		this._events.emit("nodeContext", { nodeId: n.getID(), event:e });
	};

	private onNodeOpen(n:Node){
		this._events.emit("openNode", n.getID());
	};
}

type EventHandler<T, R> = (p:T) => R;
interface EditorEvents {
	"dataChanged":EventHandler<GraphData,void>,
	"nodeContext":EventHandler<{ nodeId:string, event:any },void>,
	"openNode":EventHandler<string,void>,
	"draggedView":EventHandler<{ x:number, y:number }, void>,
	"node-position-updated":EventHandler<{ node:string, pos:{x:number, y:number} }, void>
}
