import { PhraseDictionary } from "../types";
import { mergeDictionaries } from "../mergeDictionaries";

const label:PhraseDictionary  = {
	"asset":{
		en:"Asset",
		is:"Skrá"
	},
	"assets":{
		en:"Assets",
		is:"Skrár"
	},
	"blueprint":{
		en:"Blueprint",
		is:"Könnunarsnið"
	},
	"blueprints":{
		en:"Blueprints",
		is:"Könnunarsnið"
	},
	"studyBlueprint":{
		en:"Study Blueprint",
		is:"Könnunarsnið"
	},
	"studyBlueprints":{
		en:"Study Blueprints",
		is:"Könnunarsnið"
	},
	"studyElement":{
		en:"Study Element",
		is:"Könnunareining"
	},
	"select":{
		en:"Select",
		is:"Velja"
	},
	"element":{
		en:"Element",
		is:"Eining"
	},
	"elements":{
		en:"Elements",
		is:"Einingar"
	},
	"studyElements":{
		en:"Study Elements",
		is:"Kannanaeiningar"
	},
	"recentlyModifiedAssets":{
		en:"Recently Modified Assets",
		is:"Nýlega breyttar skrár"
	},
	"form":{
		en:"Form",
		is:"Spurningalisti",
	},
	"gallery":{
		en:"Gallery",
		is:"Gallerí",
	},
	"external":{
		en:"External",
		is:"Ytri tenging",
	},
	"cognitivetest":{
		en:"Cognitive Test",
		is:"Hugrænt próf",
	},
	"scene":{
		en:"Scene",
		is:"Sena",
	},
	"newBlueprint":{
		en:"New Blueprint",
		is:"Nýtt könnunarsnið"
	},
	"newForm":{
		en:"New Form",
		is:"Nýr spurningalisti"
	},
	"newExternal":{
		en:"New External",
		is:"Ný ytri tenging"
	},
	"newGallery":{
		en:"New Gallery",
		is:"Nýtt Gallerí"
	},
	"namedVersionsOnly":{
		en:"Named Versions Only",
		is:"Titlaðar útgáfur aðeins"
	},
	"version":{
		en:"Version",
		is:"Útgáfa"
	},
	"previewNotAvailable":{
		en:"Preview Not Available",
		is:"Forskoðun ekki í boði"
	},
	"history":{
		en:"History",
		is:"Saga"
	},
	"startNode":{
		en:"Start Node",
		is:"Byrjunarnóða"
	},
	"chooseForm":{
		en:"Choose Form",
		is:"Veldu spurningalista"
	},
	"chooseExternal":{
		en:"Choose External",
		is:"Veljið ytri tengingu"
	},
	"chooseGallery":{
		en:"Choose Gallery",
		is:"Veldu Gallerí"
	},
	"availableBlueprints":{
		en:"Available Blueprints",
		is:"Könnunarsnið í boði"
	},
	"source":{
		en:"Source",
		is:"Slóð",
	},
	"sources":{
		en:"Sources",
		is:"Slóðir",
	},
	"mobileSource":{
		en:"Mobile Source",
		is:"Snjalltækjaslóð",
	},
	"mobileSources":{
		en:"Mobile Sources",
		is:"Snjalltækjaslóðir",
	},
	"panorama":{
		en:"Panorama",
		is:"Víðmynd",
	},
	"feedback":{
		en:"Feedback",
		is:"Endurgjöf",
	},
	"perItemNavigation":{
		en:"Per Item Navigation",
		is:"Takkar milli staka",
	},
	"galleryItems":{
		en:"Items",
		is:"Stök"
	},
	"showItemLabels":{
		en:"Show Item Labels",
		is:"Sýna nafn staka"
	},
	"feedbackPerItem":{
		en:"Per Item",
		is:"Fyrir hvert stak"
	},
	"selectionMode":{
		en:"Selection Method",
		is:"Slembival"
	},
	"assetImportSettings":{
		en:"Asset Import Settings",
		is:"Gagnastillingar"
	},
	"sceneFilePaths":{
		en:"File Paths",
		is:"Skrárslóðir"
	},
	"sceneAssets":{
		en:"Assets",
		is:"Skrár"
	},
	"scenePath":{
		en:"Path",
		is:"Leið"
	},
	"scenePosition":{
		en:"Position",
		is:"Hnit"
	},
	"sceneRotation":{
		en:"Rotation",
		is:"Snúningur"
	},
	"newPoint":{
		en:"New Point",
		is:"Nýr punktur",
	},
	"externalCode":{
		en:"External code",
		is:"Kóði ytri tengingar",
	},
};

const action:PhraseDictionary  = {
	"setName":{
		en:"Set Name",
		is:"Seta nafn"
	},
	"restoreVersion":{
		en:"Restore Version",
		is:"Endurkalla útgáfu"
	},
	"update":{
		en:"Update",
		is:"Uppfæra"
	},
	"updateAll":{
		en:"Update All",
		is:"Uppfæra allt"
	},
	"openForm":{
		en:"Open Form",
		is:"Opna Spurningalista"
	},
	"openGallery":{
		en:"Open Gallery",
		is:"Opna Gallerí"
	},
	"openEditor":{
		en:"Open Editor",
		is:"Opna ritil"
	},
	"filterBlueprints":{
		en:"Filter Blueprints by name",
		is:"Sía snið eftir nafni"
	},
	"filterAssets":{
		en:"Filter Assets by name",
		is:"Sía skrár eftir nafni"
	},
	"find":{
		en:"Find",
		is:"Finna"
	},
	"updateReferences":{
		en:"Update References",
		is:"Uppfæra vísanir"
	},
	"setStart":{
		en:"Set Start",
		is:"Seta sem byrjun"
	},
	"unsetStart":{
		en:"Unset Start",
		is:"Sleppa sem byrjun"
	},
	"setNodeLabel":{
		en:"Set Node Label",
		is:"Seta nóðu nafn"
	},
	"createBlueprint":{
		en:"Create Blueprint",
		is:"Búa til könnunarsnið"
	},
	"createForm":{
		en:"Create Form",
		is:"Búa til spurningalista"
	},
	"createExternal":{
		en:"Create External",
		is:"Búa til ytri tengingu"
	},
	"createGallery":{
		en:"Create Gallery",
		is:"Búa til Gallerí"
	},
	"selectBlueprint":{
		en:"Select Blueprint",
		is:"Veldu könnunarsnið"
	},
	"backToTag":{
		en:"Back to Tag",
		is:"Til baka í tag"
	},
	"showItemLabels":{
		en:"Show Item Labels",
		is:"Sýna nöfn staka"
	},
	"selectOne":{
		en:"Select One at Random",
		is:"Velja eitt"
	},
	"setSelectionMode":{
		en:"Set Selection Method",
		is:"Veldu slembiaðferð"
	},
	"snapToView":{
		en:"Snap to View",
		is:"Nota sjónarhorn"
	},
	"useExperimentalGalleryRenderer":{
		en:"Experimental Rendering",
		is:"Nota tilrauna birtingu"
	}
};

const message:PhraseDictionary  = {
	"aboutBlueprints":{
		en:"A Blueprint contains an outline of your Study, and how it should be executed.",
		is:"Snið inniheldur útlínu af könnun þinni og hvernig hún skal vera keyrð."
	},
	"aboutElements":{
		en:"You can create and reuse Elements across your Blueprints. For example, using a Form element you can define a standard questionnaire.",
		is:"Þú getur búið til og endurnotað einingar í sniðum. Til dæmis, í spurningalista geturðu fyllt inn staðlaðar spurningar."
	},
	"restoreVersion":{
		en:"This will create a copy of the version and push it to the top. Are you sure?",
		is:"Þetta býr til afrit af útgáfunni og ýtir henni efst. Ertu viss?"
	},
	"noAvailableBlueprints":{
		en:"No available Blueprints.",
		is:"Engin snið til taks."
	},
	"noExistingElements":{
		en:"No existing elements.",
		is:"Engar einingar til staðar."
	},
	"noItems":{
		en:"No items.",
		is:"Engin stök.",
	},
	"referenceUnset":{
		en:"No reference has been set.",
		is:"Engin vísun hefur verið stillt."
	},
	"referenceMissing":{
		en:"Referenced Asset is missing or deleted",
		is:"Vísun hefur verið eytt"
	},
	"aboutPanoramaToggle":{
		en:"If enabled, items will be treated like panoramas and displayed in 360° view.",
		is:"If kveikt munu stök verða birt í 360° formi."
	},
	"aboutPerItemNavigationToggle":{
		en:"If enabled, the global navigation buttons will also cycle between items.",
		is:"Ef kveikt munu munu takkar flakka á milli staka í gallerí."
	},
	"aboutItemLabelToggle":{
		en:"The currently active item will have its label displayed beneath the thumbnails (ignored if thumbnails are hidden).",
		is:"Nafn þess stak sem er virkt mun hafa nafnið birt undir smámyndunum (á ekki við er myndir eru faldar)."
	},
	"aboutGallerySettings":{
		en:"Settings pertaining to how items will be displayed.",
		is:"Stillingar sem varða hvernig stök verða birt."
	},
	"aboutGalleryFeedback":{
		en:"When enabled, a linear scale will be displayed beneath every gallery item.",
		is:"Línulegur skali verður birtur fyrir niður hvert gallerí stak.",
	},

	"aboutGallerySources":{
		en:"The first working source starting from the top of the list will be used.",
		is:"Fyrsta virkandi slóðin verður notuð, frá byrjun lista."
	},
	"aboutGalleryMobileSources":{
		en:"Mobile sources will be used if list has entries.",
		is:"Snjalltækisslóðir verða notaðar ef listi inniheldur slóðir."
	},
	"aboutGalleryItems":{
		en:"Gallery content.",
		is:"Gallerí innihald"
	},
	"aboutExperimentalGalleryRenderer":{
		en:"Gallery will be displayed with experimental renderer. Could improve performance for high resolution videos. NOTE: Could cause stability issues.",
		is:"Gallerí verður teiknað með viðmóti sem er í vinnslu. Gæti bætt keyrslu á mjög háum video upplausnum. ATH: Gæti valdið óstöðuleika."
	}
};

export const assets = mergeDictionaries("assets", { label, action, message });
