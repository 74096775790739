import { render, staticRenderFns } from "./edit-text.vue?vue&type=template&id=8bd04268&scoped=true&"
import script from "./edit-text.vue?vue&type=script&lang=ts&"
export * from "./edit-text.vue?vue&type=script&lang=ts&"
import style0 from "./edit-text.vue?vue&type=style&index=0&id=8bd04268&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8bd04268",
  null
  
)

export default component.exports