
















































































































import { defineComponent, ref, computed, PropType, provide } from '@vue/composition-api';
import { SectionList } from './section-list';
import { EditFormSection } from './edit-section';
import { Form, FormItemType, IFormTheme, ThemeProfile } from '@psychlab/types/form';
import { getFormSection, getFormItem, duplicateFormItem, duplicateFormSection } from '@form';

import { EditorNav } from './editor-nav';

import { AbsBox, Container } from '@ui';
import { RenderBackground as RenderFormBG, getThemeDefaults } from '@form-ui';
import { useTranslate } from '@lang';

import { EditFlow } from './edit-flow';
import { ThemeInspector } from './manage-themes';
import { SectionToggle } from './section-toggle';
import { usePersistentBool } from '@/hooks/usePersistentBool';

export default defineComponent({
  props: {
    data: {
      type: Object as PropType<Form>,
      required: true,
    },
  },
  components: {
    EditFormSection,
    SectionList,
    AbsBox,
    Container,
    EditorNav,
    EditFlow,
    RenderFormBG,
    ThemeInspector,
    SectionToggle,
  },
  setup(props) {
    const { translate } = useTranslate();

    const activeTab = ref('form');
    const activeId = ref<string>();
    const allowItemMove = ref(true);

    // const listOpen = ref(true);

    const { val: listOpen } = usePersistentBool('form-editor.showSections', true);

    const { val: editTheme } = usePersistentBool('form-editor.showThemes', true);

    // const editTheme = ref(true);

    const showControls = ref(true);

    const activeThemeProfile = ref<string | undefined>(props.data.defaultThemeProfile);

    const activeTheme = computed<IFormTheme>(() => {
      const profiles = props.data.themeProfiles || [];

      const at = profiles.find(p => p.id === activeThemeProfile.value);

      if (!profiles.length) {
        return getThemeDefaults();
      }

      if (!at) {
        return profiles[0].theme;
      }

      return at.theme || profiles[0].theme;
    });

    provide('formTheme', activeTheme);

    const activeSectionIndex = computed(() => {
      if (!activeId.value) {
        return -1;
      }
      const p = activeId.value.split(':');
      if (p.length < 2) {
        return -1;
      }
      if (p[0] === 's') {
        return props.data.sections.findIndex(s => s.id === p[1]);
      }
      return props.data.sections.findIndex(s => {
        return s.items.findIndex(it => it.id === p[1]) > -1;
      });
    });

    const sectionCount = computed(() => props.data.sections.length);

    const formData = computed(() => props.data);

    const sections = computed(() => {
      return formData.value ? formData.value.sections : [];
    });

    const activeSection = computed(() => {
      let i = activeSectionIndex.value;
      return i > -1 ? sections.value[i] : null;
    });

    const sectionNumber = computed(() => {
      if (!formData.value) {
        return 0;
      }
      return sections.value.length;
    });

    const addSection = () => {
      if (!formData.value) {
        return;
      }
      const s = getFormSection();
      s.label = translate('forms.label.newSection');
      formData.value.sections.push(s);
      editSection(s.id);
    };

    const addFormItem = async (e: { section: string; type: FormItemType }) => {
      const { section, type } = e;
      const item = getFormItem(type);
      let s = sections.value.find(s => s.id === section);
      s?.items.push(item);
    };

    const activeItemId = computed(() => {
      if (!activeId.value) {
        return null;
      }

      const p = activeId.value.split(':');
      if (p.length < 2) {
        return null;
      }

      if (p[0] !== 'i') {
        return null;
      }

      return p[1];
    });

    const editSection = (sid: string) => {
      activeId.value = `s:${sid}`;
    };

    const findItemIndices = (itemId: string) => {
      let i = -1;
      let j = -1;
      for (i = 0; i < sections.value.length; i++) {
        for (j = 0; j < sections.value[i].items.length; j++) {
          if (sections.value[i].items[j].id == itemId) {
            return [i, j];
          }
        }
      }
      return null;
    };

    const duplicateItem = (iid: string) => {
      const res = findItemIndices(iid);
      if (!res) {
        return;
      }
      const section = sections.value[res[0]];
      const item = section.items[res[1]];
      const copy = duplicateFormItem(item);
      section.items.splice(res[1] + 1, 0, copy);
      editSection(section.id);
    };

    const deleteFormItem = (iid: string) => {
      const res = findItemIndices(iid);
      if (!res) {
        return;
      }
      const section = sections.value[res[0]];
      section.items.splice(res[1], 1);
    };

    const deleteSection = (sectionId: string) => {
      let i = sections.value.findIndex(s => s.id === sectionId);
      if (!formData.value) {
        return;
      }
      formData.value.sections.splice(i, 1);

      if (!activeSection.value) {
        return;
      }

      const selected = activeSection.value.id === sectionId;
      if (selected) {
        let select = i;
        if (select >= sections.value.length) {
          select = sections.value.length - 1;
        }
        editSection(formData.value.sections[select].id);
      }
    };

    const duplicateSection = (sid: string) => {
      if (!formData.value) {
        return;
      }
      const s = sections.value.find(s => s.id === sid);
      if (!s) {
        return;
      }
      let c = duplicateFormSection(s);

      if (c.label) {
        c.label = translate('forms.label.copyOf').replace('$n', c.label);
      }

      formData.value.sections.push(c);
      setTimeout(() => {
        editSection(c.id);
      }, 0.1);
    };

    const init = () => {
      if (formData.value && formData.value.sections.length > 0) {
        editSection(formData.value.sections[0].id);
      }
    };

    const toggleList = () => {
      listOpen.value = !listOpen.value;
    };

    const selectSection = (sid: string) => {};

    init();

    return {
      activeTab,
      activeId,
      allowItemMove,
      activeSectionIndex,
      sectionCount,
      formData,
      // sectionSelection,
      sections,
      activeSection,
      sectionNumber,
      activeTheme,
      listOpen,
      activeItemId,
      editTheme,
      activeThemeProfile,
      showControls,
      translate,
      addSection,
      editSection,
      addFormItem,
      duplicateItem,
      deleteFormItem,
      deleteSection,
      duplicateSection,
      toggleList,
      selectSection,
    };
  },
});
