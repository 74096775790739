var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-dark"},[_c('Header',{attrs:{"filter":{
      enabled: _vm.filter.enabled,
    },"pagination":_vm.pagination},on:{"set-page":_vm.pagination.setPage,"set-limit":_vm.pagination.setLimit,"set-enabled":function($event){_vm.filter.enabled = $event},"refresh":_vm.refresh}}),_c('Divider'),_c('div',{staticClass:"p-0"},[_c('b-table',_vm._b({ref:"table",attrs:{"data-cy":"study-list"},on:{"row-clicked":function($event){return _vm.openStudyDashboard($event._id)},"sort-changed":_vm.onSortChanged,"refreshed":_vm.onRefreshed},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('EmptyMessage')]},proxy:true},{key:"head(target.study)",fn:function(){return [_vm._v(" "+_vm._s(_vm.phrases.blueprintVersion)+" ")]},proxy:true},_vm._l((['created', 'lastVisited']),function(prop){return {key:("cell(" + prop + ")"),fn:function(ref){
    var item = ref.item;
return [(item[prop])?_c('DisplayDate',{attrs:{"date":item[prop],"short":""}}):_c('span',[_vm._v("-")])]}}}),{key:"cell(name)",fn:function(ref){
    var item = ref.item;
return [_c('DescriptionButton',{attrs:{"name":item.name,"description":item.description}}),_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(item.name))])]}},{key:"cell(target.study)",fn:function(ref){
    var item = ref.item;
return [(item.target.study && item.target.version)?_c('BlueprintBadge',{attrs:{"asset":item.target.study,"version":item.target.version}}):_vm._e()]}},{key:"head(enabled)",fn:function(){return [_vm._v(" "+_vm._s(_vm.phrases.sharing)+" ")]},proxy:true},{key:"cell(enabled)",fn:function(ref){
    var item = ref.item;
return [_c('StudySharing',{staticClass:"flex-fill mr-2",attrs:{"studyId":item._id,"enabled":item.enabled}})]}},{key:"head(sessions)",fn:function(){return [_vm._v(" "+_vm._s(_vm.phrases.sessions)+" ")]},proxy:true},{key:"cell(sessions)",fn:function(ref){
    var item = ref.item;
return [_c('SessionCount',{attrs:{"studyId":item._id}})]}},{key:"cell(controls)",fn:function(ref){
    var item = ref.item;
    var index = ref.index;
return [_c('ContextButton',{attrs:{"data-cy":("study-context-menu-" + index)},on:{"click":function($event){return _vm.openContext($event, item)}}})]}}],null,true)},'b-table',_vm.tableConfig,false),[_vm._l((_vm.sortableHeaders),function(prop){return _c('template',{slot:("head(" + (prop.name) + ")")},[_c('SortableHeader',_vm._b({},'SortableHeader',prop,false))],1)})],2)],1),_c('Divider'),_c('Footer',{attrs:{"text":_vm.footerText}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }