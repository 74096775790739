






// vendor
import { defineComponent } from '@vue/composition-api';
import { Icon } from '@ui';

export default defineComponent({
  emits: ['click'],
  components: {
    Icon,
  },
});
