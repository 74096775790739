/** @format */

import { PhraseDictionary } from "../types";
import { mergeDictionaries } from "../mergeDictionaries";

const label: PhraseDictionary = {
	tag: {
		en: "Tag",
		is: "Tag",
	},
	tags: {
		en: "Tags",
		is: "Tög",
	},
	newTag: {
		en: "New Tag",
		is: "Nýtt tag",
	},
};

const action: PhraseDictionary = {
	renameTag: {
		en: "Rename Tag",
		is: "Endurnefna tag",
	},
	setTagColor: {
		en: "Set Tag Color",
		is: "Seta lit tags",
	},
	deleteTag: {
		en: "Delete Tag",
		is: "Eyða tagi",
	},
	removeTag: {
		en: "Remove Tag",
		is: "Fjarlæga tag",
	},

	editTags: {
		en: "Edit Tags",
		is: "Breyta tögum",
	},
	newTag: {
		en: "New Tag",
		is: "Nýtt tag",
	},
};

const message: PhraseDictionary = {};

export const tags = mergeDictionaries("tags", { label, action, message });
