














































import {
  defineComponent,
  ref,
  computed,
  PropType,
  reactive,
  inject,
  ComputedRef,
} from '@vue/composition-api';
import * as SessionAPI from '@/services/api/sessions';
import { useTranslate } from '@lang';
import { generateGUID } from '@utils/guid';
import { SessionContext } from '@components/sessions';
import { AbsBox, AlertBox, Container, Row, Col, Icon } from '@ui';
import { FormFooter, FormButton, RenderBackground as RenderFormBG } from '@form-ui';
import { External } from '@psychlab/types/external';
import ExternalCodeDisplay from '@components/sessions/open-session/external-code-display.vue';
import ItemBody from '@form-ui/shared/item-body.vue';
import ItemTitle from '@form-ui/shared/item-title.vue';

export default defineComponent({
  props: {
    data: {
      type: Object as PropType<External>,
      required: true,
    },
    dataNamespace: {
      type: String,
      required: true,
    },
    showBack: {
      type: Boolean,
      default: false,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ItemTitle,
    ItemBody,
    ExternalCodeDisplay,
    AlertBox,
    AbsBox,
    Container,
    Row,
    Col,
    Icon,
    FormFooter,
    FormButton,
    RenderFormBG,
  },
  setup(props, context) {
    const getSessionContext = inject<() => SessionContext>('getSessionContext');

    if (!getSessionContext) {
      throw Error('Run Form: Session context missing!');
    }

    const buttonKeys = reactive({
      back: generateGUID(),
      next: generateGUID(),
    });

    const containsErrors = ref(false);

    const loading = ref(false);
    const sectionIndex = ref(0);

    const canGoBack = computed(() => props.showBack || sectionIndex.value > 0);

    const cache = computed(() => context.root.$store.state.cache['storage']);

    const sessionId = computed(() => context.root.$route.params['sessionId']);
    const showProgressBar = inject<ComputedRef<boolean>>('showProgressBar');

    const { translate } = useTranslate();

    const regenButtonKeys = () => {
      buttonKeys.back = generateGUID();
      buttonKeys.next = generateGUID();
    };

    const next = async () => {
      context.emit('end');
      regenButtonKeys();
    };

    const back = () => {
      containsErrors.value = false;
      const i = sectionIndex.value - 1;
      if (i < 0) {
        context.emit('back');
      } else {
        sectionIndex.value = i;
      }

      regenButtonKeys();
    };

    const setProgressStep: any = inject('setProgressStep');

    const refreshProgress = () => {
      if (sectionIndex.value < 0) {
        return;
      }
      setProgressStep(`${props.dataNamespace}`);
    };

    const init = async () => {
      const sc = SessionAPI.getSessionCache(sessionId.value);
      if (sc) {
        Object.keys(sc).forEach(k =>
          context.root.$store.commit('cache/setValue', { k, v: sc[k] }),
        );
      }
      const v = cache.value[`${props.dataNamespace}.index`];
      if (v) {
        sectionIndex.value = v;
      }

      refreshProgress()
    };

    init();

    return {
      loading,
      sectionIndex,
      canGoBack,
      cache,
      buttonKeys,
      containsErrors,
      showProgressBar,
      translate,
      next,
      back,
    };
  },
});
