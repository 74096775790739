import { defineComponent, computed, ref } from '@vue/composition-api';
import { AssetVersion } from '@/psychlab/types/assets';
import { useAssets } from '@/hooks/useAssets';
import { getAssetVersion } from '@/services/api/assets';
import { countStudySessions } from '@/services/api/studies';
import { useTranslate } from '@lang';
import { Icon, AbsBox } from '@ui';

enum Translations {
  CopyShareLink = 'studies.action.copyShareLink',
}

export const BlueprintBadge = defineComponent({
  props: {
    asset: { type: String, required: true },
    version: { type: String, required: true },
    variant: { type: String, default: 'info' },
  },
  setup(props) {
    const { assets } = useAssets();

    const version = ref<AssetVersion<any>>();

    const asset = computed(() => assets.value.find(a => a._id === props.asset));

    const assetName = computed(() => asset.value?.name || '');

    const versionLabel = computed(() =>
      version.value ? `v${(version.value.vIndex + 1).toString()}` : '',
    );

    const init = async () => {
      try {
        version.value = await getAssetVersion(props.asset, props.version);
      } catch {}
    };

    init();

    return {
      assetName,
      versionLabel,
    };
  },
  render() {
    return (
      <div>
        <small style="border-radius:1em;font-family:'NATS';" class="my-auto px-2 text-light">
          <span>{this.assetName}</span>
          <span class="text-info mx-1">@</span>
          <span>{this.versionLabel}</span>
        </small>
      </div>
    );
  },
});

export const SessionCount = defineComponent({
  props: {
    studyId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const loading = ref(true);
    const count = ref(0);

    const init = async () => {
      try {
        loading.value = true;
        count.value = await countStudySessions(props.studyId);
        loading.value = false;
      } catch (err) {
        console.error('Unable to load sessions count');
      }
    };

    init();
    return { count, loading };
  },
  render() {
    return <span>{this.loading ? '' : this.count}</span>;
  },
});

export const StatusBadge = defineComponent({
  props: {
    enabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const variant = computed(() => (props.enabled ? 'success' : 'secondary'));
    const label = computed(() => (props.enabled ? 'Live' : 'Closed'));

    return {
      variant,
      label,
    };
  },
  render() {
    return (
      <b-badge
        variant={this.variant}
        class="p-1 px-2 w-100"
        style="font-size:1rem; font-family:'NATS'"
      >
        {this.label}
      </b-badge>
    );
  },
});

const getShareURL = (studyId: string) => `p/s/${studyId}`;

export const StudySharing = defineComponent({
  props: {
    studyId: {
      type: String,
      required: true,
    },
    enabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { translate } = useTranslate();

    const urlBox = ref<HTMLInputElement>();

    const url = computed(() => `${window.location.origin}/${getShareURL(props.studyId)}`);

    const copy = () => {
      if (!urlBox.value) {
        return;
      }
      urlBox.value.select();
      document.execCommand('copy');
    };

    return {
      url,
      urlBox,
      copy,
      translate,
    };
  },
  render() {
    if (!this.enabled) {
      return (
        <div class="d-flex h-100 py-1" style="height:1em;">
          <Icon name="lock" class="m-auto text-secondary" />
        </div>
      );
    }

    const copybox = (
      <input
        readonly
        ref="urlBox"
        value={this.url}
        class="form-control form-control-lg"
        style="cursor:pointer;font-family:consolas;font-size:1em;opacity:0"
      />
    );

    return (
      <div class="h-100 w-100">
        <div class="h-100" style="position:relative">
          {copybox}
          <AbsBox>
            <button
              class="btn btn-md btn-outline-success shadow-none align-middle w-100 h-100 p-1"
              v-on:click={this.copy}
              v-b-tooltip={{
                title: this.translate(Translations.CopyShareLink),
                interactive: false,
                delay: 0,
                animation: false,
                placement: 'right',
              }}
            >
              <Icon name="link-variant" class="m-auto text-light" />
            </button>
          </AbsBox>
        </div>
      </div>
    );
  },
});
