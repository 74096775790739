


































import { defineComponent, computed, PropType } from "@vue/composition-api";
import { FormSection, FormItemType } from "@psychlab/types/form";
import { getFormItem, duplicateFormItem } from "@form";
import { useTranslate } from "@lang";
import { Draggable, Container, Icon } from "@ui";
import { default as AddItemButton } from "./add-button.vue";
import { EditFormItem } from "../edit-item";

const insert = <T>(arr:T[], i:number, item:T) => arr.splice(i, 0, item);


const dragOptions = {
	animation: 200,
	disabled: false,
	ghostClass: "ghost",
	forceFallback: true,
	handle:".drag-btn",
};

type SectionInfo  = {
	index:number,
	count:number
}

export default defineComponent({
	props:{
		section:{
			type:Object as PropType<FormSection>,
			required:true
		},
		activeItem:{
			type:String,
			required:false,
		},
		allowDrag:{
			type:Boolean
		},
		info:{
			type:Object as PropType<SectionInfo>,
			required:true
		},
		showControls:{
			type:Boolean,
			default:true,
		}
	},
	components: {
		AddItemButton,
		EditFormItem,
		Draggable,
		Container,
		Icon,
	},
	setup(props){

		const { translate } = useTranslate();

		const items = computed(() => {

			if(!props.activeItem){
				return props.section.items;
			}

			const item = props.section.items.find(it => it.id === props.activeItem);

			if(item){ return [ item ]; }
			return [];
		});

		const add = (type:FormItemType) => {
			const item = getFormItem(type);
			item.name = translate(`forms.label.new${type}`)
			props.section.items.push(item);
		};

		const deleteItem = (iid:string) => {
			const i = props.section.items.findIndex(it => it.id === iid);
			if(i < 0){ return; }
			props.section.items.splice(i, 1);
		};

		const duplicateItem = (iid:string) => {
			const i = props.section.items.findIndex(it => it.id === iid);
			if(i < 0){ return; }
			const item = props.section.items[i]
			const c = duplicateFormItem(item);
			if(c.name){
				c.name = translate("forms.label.copyOf").replace("$n", c.name);
			}
			insert( props.section.items, i + 1, c);
		};


		return {
			items,
			dragOptions,
			add,
			deleteItem,
			duplicateItem,
		};
	}

});




