import { render, staticRenderFns } from "./run-external.vue?vue&type=template&id=ea70a35a&scoped=true&"
import script from "./run-external.vue?vue&type=script&lang=ts&"
export * from "./run-external.vue?vue&type=script&lang=ts&"
import style0 from "./run-external.vue?vue&type=style&index=0&id=ea70a35a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea70a35a",
  null
  
)

export default component.exports