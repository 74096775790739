import { defineComponent, ref, computed, watch, provide } from "@vue/composition-api";
import { default as Router } from "vue-router";
import { getSessionCache, loadSession } from "@/services/api/sessions";
import { getStudyInfo } from "@/services/api/studies";
import { getDelay } from "@utils/time";
import { RunGraph } from "@/components.run/run-graph";
import { Session } from "@/psychlab/types/sessions";
import { StudyInfo } from "@/psychlab/types/studies";
import { isMobile as isMobileDevice } from "@utils/device";
import { SessionProgressBar } from "./SessionProgressBar";
import { SessionContext } from "./SessionContext";
import { getProgressPath } from "./getProgressPath";
import { AbsBox } from "@ui";
import { RequestMotion } from "@components/mobile";
import { CompanySplashScreen } from "@components/misc";
import { ProvideStudyLanguage } from "@components/studies";

enum ScreenType {
	NotFound = "not-found",
	SurveyComplete = "survey-complete",
}

enum RouteNames {
	InfoScreen = "Error",
}

const openScreen = (e:ScreenType, r:Router, q?:any) => {
	r.push({
		name:RouteNames.InfoScreen,
		params:{ slug:e, },
		query:q
	});
};

export const OpenSession = defineComponent({
	props:{
		sessionId:{
			type:String,
			required:true,
		}
	},
	setup(props, context){

		const store = context.root.$store;
		const router = context.root.$router;
		const route = computed(() => context.root.$route);

		const error = ref(false);
		const loading = ref(false);
		const session = ref<Session>();
		const study = ref<StudyInfo>();
		const currentProgressStep = ref("");
		const progressReady = ref(true);
		const setupReady = ref(false);
		const useAccelerometer = ref(false);
		const progressVisible = computed(() => {
			if(!session.value){ return false; }
			if(finished.value){ return false; }
			if(study.value && !study.value.showProgress){
				return false;
			}
			return progressReady.value;
		});

		const routeLanguage = computed(() => {
			if(!study.value){ return ""; }
			let l = study.value.language || "en";
			if(route.value.query["l"]){
				l = route.value.query["l"] as string;
			}
			return l;
		});

		const preferredLanguage = computed(() => {
			if(study.value){
				return study.value.language;
			}
			return routeLanguage.value;
		});

		const sessionName = computed(() => session.value ? session.value.name : undefined);

		const finished = computed(() => {
			return !session.value ? false : session.value.state.node === null;
		});

		const isMobile = computed(() => isMobileDevice());

		const accelerometerEnabled = computed(() => useAccelerometer.value);

		const progressSteps = computed(() => {
			if(!session.value || !session.value.graph){ return []; }
			return getProgressPath({
				graph:session.value.graph,
				path:session.value.path
			});
		});

		const cache = computed<Record<string,any>>(() => store.state.cache["storage"]);

		watch(sessionName, (v) => {
			if(v !== undefined){ document.title = v; }
		});

		const setMotionAllowed = (allowed:boolean) => {
			useAccelerometer.value = allowed;
			setupReady.value = true;
		};

		const loadSessionCache = () => {
			const sc = getSessionCache(props.sessionId);
			if(sc){
				Object.keys(sc).forEach(k => context.root.$store.commit("cache/setValue", { k, v:sc[k] }));
			}
		};

		const setProgressStep = (name:string) => currentProgressStep.value = name;

		const progressStepCount = computed(() => progressSteps.value.length);

		const progressStepIndex = computed(() => {
			return progressSteps.value.findIndex(s => s === currentProgressStep.value);
		});

		const init = async() => {
			loading.value = true;
			try{
				session.value = await loadSession(props.sessionId);
				if(session.value){
					study.value = await getStudyInfo(session.value.group);
				}
			}
			catch(err){
				error.value = true;
				console.error(err);
			}

			if(error.value){
				openScreen(ScreenType.NotFound, router, {
					l:preferredLanguage.value,
				});
				return;
			}

			if(!finished.value){
				loading.value = false;
				await getDelay(1000);
			}
			else {
				loading.value = false;
			}

			if(!isMobile.value){
				setupReady.value = true;
			}

			loadSessionCache();
		};

		init();

		watch(finished, v => {
			if(!v){ return; }
			openScreen(ScreenType.SurveyComplete, router, {
				l:preferredLanguage.value,
			});
		})

		provide("setProgressReady", (v:boolean) => progressReady.value = v);

		provide("setProgressStep", setProgressStep);

		provide("showProgressBar", progressVisible);

		provide("getSessionContext", ():SessionContext => {
			return {
				sessionId:computed(() => props.sessionId),
				isMobile,
				progressStepCount,
				progressStepIndex,
				progressVisible,
				accelerometerEnabled,
				cache,
				setProgressStep,
			};
		});

		return {
			showProgress:progressVisible,
			loading,
			session,
			currentProgressStep,
			setupReady,
			preferredLanguage,
			setMotionAllowed,
		};
	},
	render(){

		let progress = null;

		let content = <span/>;



		if(this.showProgress){
			progress = <SessionProgressBar/>;
		}

    if(!this.loading && !this.setupReady){

      content = (
        <RequestMotion
          v-on:allowed={ () => this.setMotionAllowed(true) }
          v-on:denied={ () => this.setMotionAllowed(false) }
        />
      )
    }
		else if(this.session && this.setupReady){
			content = (
				<div class="flex-fill" style="position:relative;">
					<AbsBox>
						<RunGraph session={ this.session }/>
					</AbsBox>
				</div>
			)
		}

		return (
			<ProvideStudyLanguage lang={ this.preferredLanguage }>
				<AbsBox
				class="d-flex flex-column"
				style="overflow:hidden;"
				>
					{ progress }
					{ content }
					<CompanySplashScreen
					show={ this.loading }
					/>
				</AbsBox>
			</ProvideStudyLanguage>
		);
	}
});
