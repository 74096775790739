import { http } from '@/http';
import { ExternalCode } from '@psychlab/types';
import { getSessionToken } from '@/services/api/sessions';

export const getExternalCode = async (sessionId: string): Promise<ExternalCode> => {
  let token = getSessionToken(sessionId);
  let options: any = { headers: {} };
  if (token) {
    options.headers.Authorization = token;
  }
  const { data } = await http.post(`sessions/${sessionId}/code`, undefined, options);
  return data;
};
