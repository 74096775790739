import { SetupContext } from '@vue/composition-api';
import { openContextMenu } from '@ui';
import { useTranslate } from '@lang';
import { getItemTypes } from '@form';
import { FormItemType } from '@psychlab/types/form';
import { useFormIcons } from './useFormIcons';

export const useAddFormItem = (context: SetupContext | ((t: FormItemType) => void)) => {
  const { translate } = useTranslate();

  const { getItemIcon } = useFormIcons();

  const add = (e: Event) => {
    const options = getItemTypes().map(t => {
      return {
        'name': translate(`forms.label.${t.toLowerCase()}`),
        'icon': getItemIcon(t),
        'data-cy': `form-item-${t}`,
        'fn'() {
          if (typeof context === 'function') {
            context(t);
          } else {
            context.emit('add', t);
          }
        },
        'order': itemMenuOrder[t] || 0,
      };
    });
    openContextMenu(e, options);
  };

  return {
    add,
  };
};

const itemMenuOrder: { [key in FormItemType]?: number } = {
  Information: -2,
  Image: 4,
  Video: 4,
  HTML: 6,
  LinearScale: 2,
  Answer: -1,
};
