import { defineComponent, computed } from '@vue/composition-api';
import { Navbar, Sidebar } from '@components/nav';
import { useUserPrefs } from '@/hooks/useUserPrefs';
import { default as AppModals } from './AppModals.vue';
import { ProvideRouteLang } from '@lang';
import { AbsBox } from '@ui';

const SiteBackground = defineComponent({
  components: { AbsBox },
  template: `<AbsBox style="background:#576063;"/>`,
});

export const App = defineComponent({
  setup(_, context) {
    const store = context.root.$store;

    const route = computed(() => context.root.$route);

    const { menuOpen } = useUserPrefs();

    const hasAuth = computed(() => {
      return store.getters.authenticated;
    });

    const showNav = computed(() => {
      const hidden = Boolean(route.value.meta?.navHidden);
      if (hidden) {
        return false;
      }
      return store.getters.authenticated;
    });

    const showSidebar = computed(() => showNav.value && menuOpen.value);

    const routeKey = computed(() => {
      const r = route.value;
      if (r.meta && r.meta.viewKey) {
        let s: string = r.meta.viewKey;
        Object.keys(r.params).forEach(pk => {
          s = s.replaceAll(`$${pk}`, r.params[pk]);
        });
        return s;
      }
      return r.path;
    });

    return {
      showNav,
      menuOpen,
      routeKey,
      hasAuth,
      showSidebar,
    };
  },

  render() {
    const modals = this.hasAuth ? <AppModals /> : <span />;

    const sidebar = this.showSidebar ? (
      <Sidebar v-on:hide={() => (this.menuOpen = false)} />
    ) : (
      <span />
    );

    const navbar = this.showNav ? (
      <Navbar menuState={this.menuOpen} v-on:open-menu={() => (this.menuOpen = true)} />
    ) : (
      <span />
    );

    return (
      <div id="app">
        {modals}

        <SiteBackground />

        <AbsBox class="d-flex flex-row">
          {sidebar}

          <div class="flex-fill d-flex flex-column">
            {navbar}
            <div class="flex-fill" style="position:relative;">
              <ProvideRouteLang>
                <AbsBox>
                  <router-view key={this.routeKey} />
                </AbsBox>
              </ProvideRouteLang>
            </div>
          </div>
        </AbsBox>
      </div>
    );
  },
});
