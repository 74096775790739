





























import { defineComponent, PropType, computed } from '@vue/composition-api';
import { FindAsset } from '@/components.assets/find-asset';
import { ExternalParams } from '../../ts/templates/External';
import { useTranslate } from '@lang';
import { Container } from '@ui';

export default defineComponent({
  components: {
    FindAsset,
    Container,
  },
  props: {
    parameters: {
      type: Object as PropType<ExternalParams>,
      required: true,
    },
  },
  setup(props) {
    const { translate } = useTranslate();

    const asset = computed({
      get: () => props.parameters.asset,
      set: v => (props.parameters.asset = v),
    });

    return {
      asset,
      translate,
    };
  },
});
