import { defineComponent } from '@vue/composition-api';
import { AbsBox, Icon } from '@ui';
import { Gradient } from '@/errors/Backgrounds';

export const Loading = defineComponent({
  render() {
    return (
      <AbsBox>
        <Gradient />
        <AbsBox class="d-flex">
          <h1 class="m-auto text-light">
            <Icon name="cog" spin style="font-size:6rem" />
          </h1>
        </AbsBox>
      </AbsBox>
    );
  },
});
