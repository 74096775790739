






































// vendor
import { defineComponent, computed, PropType } from "@vue/composition-api";
// project
import { generateGUID } from "@/utils/guid";

import { useTranslate } from "@lang";
import { Icon } from "@ui";



type CustomAction = {
	icon:string,
	tooltip:string,
	variant:string;
	fn:() => any,
	disabled?:boolean
};



export default defineComponent({

	props:{
		isStart:{
			type:Boolean,
			default:false
		},
		iconSize:{
			type:Number,
			default:0.7
		},
		warningCount:{
			type:Number,
			default:0
		},
		actions:{
			type:Array as PropType<CustomAction[]>,
			default:() => []
		}
	},
	components:{
		Icon,
	},
	setup(props){

		const { translate } = useTranslate();


		const buttonSize = computed(() => {
			return props.iconSize + 0.6;
		});

		const displayActions = computed(() => {

			const r:any[] = [];

			if(props.isStart){
				r.push({
					key:"start-node",
					tooltip:translate("assets.label.startNode"),
					variant:"success",
					tooltipVariant:"success",
					icon:"mdi.flag-variant"
				});
			}

			if(props.warningCount > 0){
				r.push({
					key:"warnings",
					// tooltip:`${props.warningCount} Warning(s)`,
					tooltip:`${translate("assets.label.warnings")} (${props.warningCount})`,
					variant:"warning",
					tooltipVariant:"warning",
					txtVariant:"danger",
					icon:"mdi.alert-circle-outline"
				});
			}

			props.actions.forEach(a => {
				r.push({
					key:generateGUID(),
					tooltip:a.tooltip,
					variant:`outline-${a.variant}`,
					tooltipVariant:a.variant,
					icon:a.icon,
					fn:a.fn,
					disabled:a.disabled

				})
			});
			return r;

		});

		const runAction = (e:any, a:any) => {
			if(a.fn){
				a.fn(e);
			}
		};


		return {
			displayActions,
			buttonSize,
			runAction,
			translate,
		};
	}
});

