























































import { defineComponent, ref, computed } from "@vue/composition-api";

import { ReorderableList } from "@ui";
import { useTranslate } from "@lang";

export interface IEditLikertModal {
	open:(c:Config, fn:DoneFn) => void;
}

type Config = {
	rows:string[];
	columns:string[];
};

type Result = {
	rows:string[];
	columns:string[];
};

type DoneFn = (r:Result) => void;

type Modal = {
	hide():void
};

export default defineComponent({
	components: {
		ReorderableList,
	},
	setup(){

		const { translate } = useTranslate();

		const modal = ref<Modal>();
		const onDone = ref<DoneFn>();
		const show = ref(false);
		const columns = ref<string[]>([]);
		const rows = ref<string[]>([]);

		const ok = () => {

			const trim = (opts:string[]) => opts.forEach((o, i) => opts[i] = o.trim());

			trim(rows.value);
			trim(columns.value);

			if(onDone.value){
				onDone.value({
					rows:rows.value,
					columns:columns.value
				});
			}

			if(modal.value){
				modal.value.hide();
			}
		};

		const hide = () => {
			onDone.value = undefined;
			show.value = false;
			rows.value = [];
			columns.value = [];
		};

		const open = (c:Config, fn:DoneFn) => {



			onDone.value = fn;
			show.value = true;
			rows.value = [ ...c.rows ];
			columns.value = [ ...c.columns ];
		};

		return {
			modal,
			onDone,
			show,
			rows,
			columns,
			ok,
			hide,
			open,
			translate,
		};
	}
});



