


















































import { defineComponent, computed, PropType } from '@vue/composition-api';
import { EditorExternalVariable, External } from '@psychlab/types/external';

import { AbsBox, Container } from '@ui';
import { RenderBackground as RenderFormBG } from '@form-ui';
import { useTranslate } from '@lang';
import { nanoid } from 'nanoid';
import VariableRow from '@/components.edit/edit-external/components/variable-row.vue';

export default defineComponent({
  props: {
    data: {
      type: Object as PropType<External>,
      required: true,
    },
  },
  components: {
    VariableRow,
    AbsBox,
    Container,
    RenderFormBG,
  },
  setup(props) {
    const { translate } = useTranslate();

    const variablesData = computed<EditorExternalVariable[]>(() => props.data.variables);

    const addVariable = () => {
      variablesData.value.push({ id: nanoid(), key: '' });
    };

    const confirmVariable = ({ id, value }: { id: string; value: string }) => {
      const inx = variablesData.value.findIndex(i => i.id === id);
      variablesData.value[inx].key = value;
    };

    const deleteVariable = (id: string) => {
      const inx = variablesData.value.findIndex(i => i.id === id);
      variablesData.value.splice(inx, 1);
    };

    const init = () => {};

    init();

    return {
      variablesData,
      translate,
      addVariable,
      deleteVariable,
      confirmVariable,
    };
  },
});
