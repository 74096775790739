

























































































































































import { defineComponent, PropType, set, computed } from "@vue/composition-api";
import { Form, FormItem, FormSection, ShuffleOption } from "@psychlab/types/form";
import { AbsBox, Icon } from "@ui";

import { useFormIcons } from "@form-ui";
import { useTranslate } from "@lang";
import { useItemFlowContext } from "./useItemFlowContext";

import { default as ShuffleButton } from "./shuffle-btn.vue";

import { default as OptionalButton } from "./optional-btn.vue";
import { default as VEdge } from "./vedge.vue";
import { default as HEdge } from "./hedge.vue";
import { default as EdgeTree } from "./edge-tree.vue";
import { default as PlatformButton } from "./platform-btn.vue";
import { default as Legend } from "./legend.vue";

const isValueType = (t:string) => [
	"Information", "HTML", "Image", "Video"
].indexOf(t) < 0;


export default defineComponent({

	emits:[],
	props:{
		data:{
			type:Object as PropType<Form>,
			required:true
		},
		activeSelection:{
			type:String,
			default:"",
		},
	},
	components:{
		AbsBox,
		Icon,
		ShuffleButton,
		OptionalButton,
		VEdge,
		HEdge,
		PlatformButton,
		EdgeTree,
		Legend,
	},
	setup(props){

		const { getItemIcon } = useFormIcons();
		const { translate } = useTranslate();


		const {
			open: openItemFlow
		} = useItemFlowContext();


		const getSectionShuffle = (s:FormSection) => {
			return s.settings?.itemSelection || null;
		};

		const activeId = computed(() => {

			if(!props.activeSelection){
				return null;
			}

			const p = props.activeSelection.split(":");

			if(p.length < 2){
				return null;
			}
			return p[1];

		})


		const setSectionShuffle = (s:FormSection, v:ShuffleOption|null) => {
			let settings = s.settings;
			if(!settings){
				set(s, "settings", { itemSelection: v })
			}
			else {
				set(settings, "itemSelection", v);
			}
		};


		const getSectionTitle = (s:FormSection) => {
			return  s.label || `(${translate('label.untitled').toLowerCase()})`;
		};


		const getFormShuffle = () => {
			return props.data.settings.sectionSelection || null;
		};

		const setFormShuffle = (v:ShuffleOption|null) => {
			set(props.data.settings, "sectionSelection", v)
		};

		const configureItem = (e:Event, it:FormItem<any>) => {
			openItemFlow(e, it);
		};


		const getItemTooltip = (item:FormItem<any>) => {
			const type = translate(`forms.label.${item.type.toLowerCase()}`);
			const title = item.name || `(${translate("label.untitled").toLowerCase()})`;
			const s = `
			<span style="font-weight:bold">
			${type}
			</span>
			<hr style="width:100%;background:white;opacity:0.5;margin:0.1em 0"/>
			<span style="font-style:italic">
			${title}
			</span>
			`;
			return s;
		};


		return {
			activeId,
			getItemIcon,
			getSectionShuffle,
			setSectionShuffle,
			getFormShuffle,
			setFormShuffle,
			translate,
			getSectionTitle,
			configureItem,
			isValueType,
			getItemTooltip,
		};
	}
});

