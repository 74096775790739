




























import { defineComponent, computed, PropType } from '@vue/composition-api';
import { ItemBox, getBaseProps } from '../shared';
import { EditOptions } from '../edit-options';
import { useTranslate } from '@lang';
import { AbsBox } from '@ui';

export default defineComponent({
  emits: ['input'],
  props: {
    value: {
      type: Number,
      default: -1,
    },
    options: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    ...getBaseProps(),
  },
  components: {
    ItemBox,
    EditOptions,
    AbsBox,
  },
  setup(props, ctx) {
    const { translate } = useTranslate();

    const dlabel = `(${translate('label.untitled').toLowerCase()})`;

    const displayOptions = computed(() => {
      return props.options.map((o, i) => {
        return {
          value: i,
          text: o || dlabel,
        };
      });
    });

    const val = computed({
      get: () => props.value,
      set: v => ctx.emit('input', v),
    });

    const toggleValue = (v: number) => {
      val.value = v;
    };

    return {
      val,
      displayOptions,
      toggleValue,
    };
  },
});
