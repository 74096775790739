




























































































import { defineComponent, computed, ref, watch, provide } from '@vue/composition-api';
import { Route } from 'vue-router';
import * as AssetAPI from '@/services/api/assets';
import { Asset, AssetVersion } from '@/psychlab/types';
import { useAssets } from '@/hooks/useAssets';
import { PreviewAssetData } from '@/components.assets/preview-asset-data';
import { ContextOption } from '@/components.generic/context-menu';
import * as AppModal from '@/AppModal';
import { AssetContext } from './AssetContext';
import { UpdateButton } from './UpdateButton';
import { useTranslate } from '@lang';
import { AbsBox, Date as DateLabel, Icon } from '@ui';
import { ContextHeader } from '@components/nav';
import { PageFavouriteButton } from '@components/favourites';

const ContextTarget = defineComponent({
  template: `<portal-target name="asset-context"/>`,
});

enum RouteNames {
  ViewAsset = 'view-asset',
  EditAsset = 'asset.edit',
  ViewAssetHistory = 'asset-history',
  ViewTag = 'view-tag',
  AssetHome = 'assets',
}

enum Translations {
  Rename = 'action.rename',
  Edit = 'action.edit',
  ViewHistory = 'action.viewHistory',
  Overview = 'label.overview',
  LastModified = 'label.lastModified',
}

const getBackRoute = (cfg: { prev?: Route; current: Route }) => {
  const { prev, current } = cfg;

  if (prev?.name === RouteNames.ViewTag) {
    return {
      name: RouteNames.ViewTag,
      params: { tagId: prev.params['tagId'] },
    };
  }

  if (prev?.name === RouteNames.AssetHome) {
    return {
      name: RouteNames.AssetHome,
      query: { t: prev.query['t'] },
    };
  }

  if (current.query['t']) {
    return {
      name: RouteNames.AssetHome,
      query: { t: current.query['t'] },
    };
  }
  return null;
};

export default defineComponent({
  components: {
    PreviewAssetData,
    UpdateButton,
    DateLabel,
    Icon,
    ContextHeader,
    ContextTarget,
    AbsBox,
    PageFavouriteButton,
  },
  beforeRouteEnter(_, from, next) {
    next(vm => {
      const setPrevRoute = (vm as any).setPrevRoute;
      if (setPrevRoute) {
        setPrevRoute(from);
      }
    });
  },
  setup(_, context) {
    const router = context.root.$router;
    const route = computed(() => context.root.$route);
    const assetHooks = useAssets();
    const latestVersion = ref<AssetVersion<any>>();
    const assetId = computed(() => route.value.params['assetId']);
    const asset = ref<Asset>();

    const { translate } = useTranslate();

    const prevRoute = ref<Route>();

    const backTag = ref<string>();

    const isEditing = computed(() => route.value.name === RouteNames.EditAsset);
    const isHistory = computed(() => route.value.name === RouteNames.ViewAssetHistory);
    const isViewing = computed(() => route.value.name === RouteNames.ViewAsset);

    const backRoute = computed(() =>
      getBackRoute({
        prev: prevRoute.value,
        current: route.value,
      }),
    );

    const viewRoute = computed(() => ({
      name: RouteNames.ViewAsset,
      params: { assetId: assetId.value },
    }));

    const editRoute = computed(() => ({
      name: RouteNames.EditAsset,
      params: { assetId: assetId.value },
    }));

    const historyRoute = computed(() => ({
      name: RouteNames.ViewAssetHistory,
      params: { assetId: assetId.value },
    }));

    const showUpdate = computed(() => {
      if (asset.value?.type !== 'Graph') {
        return false;
      }
      return true;
    });

    const openRoute = (r: any) => router.push(r).catch(() => {});

    const updateRefs = () => {};

    const rename = () => {
      const a = asset.value;
      if (!a) {
        return;
      }

      AppModal.editText(
        {
          title: translate(Translations.Rename),
          value: a.name,
          validator(v) {
            return v !== a.name && v.length > 1;
          },
        },
        async v => {
          try {
            await assetHooks.renameAsset(a._id, v);
            a.name = v;
          } catch (err: any) {
            console.log(err.message);
          }
        },
      );
    };

    const setPrevRoute = (r: Route) => {
      prevRoute.value = r;
    };

    const onUpdated = () => {
      init();
    };

    const openContext = (e: Event) => {
      const options: ContextOption[] = [
        {
          name: translate(Translations.Rename),
          icon: 'pencil',
          fn: rename,
        },
      ];

      if (!isViewing.value) {
        options.push({
          name: translate(Translations.Overview),
          icon: 'information-outline',
          fn: () => openRoute(viewRoute.value),
          order: 1,
        });
      }

      if (!isEditing.value) {
        options.push({
          name: translate(Translations.Edit),
          icon: 'pencil-box',
          fn: () => openRoute(editRoute.value),
          order: 2,
        });
      }

      if (!isHistory.value) {
        options.push({
          name: translate(Translations.ViewHistory),
          icon: 'history',
          fn: () => openRoute(historyRoute.value),
          order: 3,
        });
      }
      AppModal.context(e, options);
    };

    const init = async () => {
      if (!asset.value) {
        asset.value = await AssetAPI.getAsset(assetId.value);
      }

      latestVersion.value = await AssetAPI.getAssetVersion(assetId.value, 'latest');
      refreshPageTitle();
    };
    const refreshPageTitle = () => {
      if (!asset.value) {
        return;
      }
      document.title = `${asset.value.name}`;
    };

    const getRouteTag = () => {
      return route.value.query['t'] as string | undefined;
    };

    init();

    watch(asset, refreshPageTitle);

    backTag.value = getRouteTag();

    provide<AssetContext>('assetContext', {
      asset,
      assetId,
      backTag: getRouteTag(),
    });

    return {
      asset,
      latestVersion,
      editRoute,
      historyRoute,
      viewRoute,
      isEditing,
      isHistory,
      isViewing,
      showUpdate,
      backRoute,
      prevRoute,
      onUpdated,
      translate,
      setPrevRoute,
      openContext,
      updateRefs,
    };
  },
});
