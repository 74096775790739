







// vendor
import { defineComponent, computed, ref, PropType } from '@vue/composition-api';

import { AbsBox } from '@ui';
import { default as Hoverlay } from '../shared/hoverlay.vue';

import { default as EditOptionsModal } from './edit-options-modal.vue';
import { IEditOptionsModal } from './edit-options-modal.vue';

export default defineComponent({
  emits: ['input'],
  props: {
    options: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  components: {
    AbsBox,
    Hoverlay,
    EditOptionsModal,
  },
  setup(props) {
    const modal = ref<IEditOptionsModal>();

    const edit = () => {
      if (!modal.value) {
        return;
      }

      modal.value.open(
        {
          options: props.options,
        },
        opts => {
          props.options.length = 0;
          props.options.push(...opts);
        },
      );
    };

    return {
      modal,
      edit,
    };
  },
});
