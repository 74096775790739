







// vendor
import { defineComponent, ref, PropType } from '@vue/composition-api';

import { AbsBox } from '@ui';
import { default as Hoverlay } from '../shared/hoverlay.vue';

import { default as EditLikertModal } from './edit-likert-modal.vue';
import { IEditLikertModal } from './edit-likert-modal.vue';

export default defineComponent({
  props: {
    rows: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    columns: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  components: {
    AbsBox,
    Hoverlay,
    EditLikertModal,
  },
  setup(props) {
    const modal = ref<IEditLikertModal>();

    const edit = () => {
      if (!modal.value) {
        return;
      }
      modal.value.open(
        {
          rows: props.rows,
          columns: props.columns,
        },
        ({ rows, columns }) => {
          props.rows.length = 0;
          props.rows.push(...rows);
          props.columns.length = 0;
          props.columns.push(...columns);
        },
      );
    };

    return {
      modal,
      edit,
    };
  },
});
