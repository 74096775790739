





































































































import { defineComponent, computed, ref, PropType, watch } from "@vue/composition-api";
import { Asset, AssetVersion } from "@/psychlab/types/assets";
import * as AssetAPI from "@/services/api/assets";
import { FullscreenModal } from "@ui";
// import { FullscreenModal } from "@/components.generic/fullscreen-modal";
import { ViewAssetHistory } from "@/components.assets/view-asset-history";
import { PreviewAssetData } from "@/components.assets/preview-asset-data";
import { useAssets } from "@/hooks/useAssets";
import { ModalConfig, ResultFn } from "./types";
import { default as AssetList } from "./asset-list.vue";
import { useTranslate } from "@lang";

export default defineComponent({

	emits:[],
	components:{
		FullscreenModal,
		AssetList,
		ViewAssetHistory,
		PreviewAssetData,
	},
	setup(props, context){

		const modal = ref<any>();
		const config = ref<ModalConfig|null>(null);
		const onResult = ref<ResultFn|null>(null);

		const activeAssetId = ref<string|null>(null);
		const activeVersionId = ref<string|null>(null);

		const activeVersion = ref<AssetVersion<any>|null>(null);

		const versions = ref<AssetVersion<any>[]>([]);

		const showHistory = ref(false);

		const tag = ref<string>();


		const {
			translate,
		} = useTranslate();




		const {
			assets
		} = useAssets();

		const assetType = computed(() => config.value ? config.value.assetType : null);

		const canConfirm = computed(() => {
			if(!config.value || !config.value.value) { return false; }
			const { asset, version } = config.value.value;
			if(asset === activeAssetId.value && version === activeVersionId.value){
				return false;
			}
			return Boolean(activeAssetId.value) && Boolean(activeVersionId.value);
		});


		const activeAsset = computed(() => {
			return assets.value.find(a => a._id === activeAssetId.value);
		})


		const usingLatest = computed(() => {
			if(versions.value.length === 0 || !activeVersion.value){ return false; }
			const max = Math.max(...versions.value.map(v => v.vIndex));
			return max === activeVersion.value.vIndex;
		});

		const open = (c:ModalConfig, fn:ResultFn) => {
			if(!modal.value){ return; }
			modal.value.open();
			config.value = c;

			if(!c.value){
				c.value = {
					asset:null,
					version:null
				}
			}

			tag.value = c.tag;

			onResult.value = fn;
			if(c.value){
				const v = c.value.version;
				activeAssetId.value = c.value.asset;
				setTimeout(() => {
					activeVersionId.value = v;
				}, 2);
			}
		};



		const submit = () => {

			const r = {
				asset:activeAssetId.value || "",
				version:activeVersionId.value || ""
			};

			if(onResult.value){
				onResult.value(r);
			}

			hide();
		};

		const hide = () => {
			if(modal.value){
				modal.value.close();
			}
			config.value = null;
			activeAssetId.value = null;
			activeVersionId.value = null;
			onResult.value = null;
			activeVersion.value = null;
			showHistory.value = false;
			versions.value = [];
		};

		const useLatest = async () => {


			if(!activeAssetId.value){ return; }
			try {
				if(!versions.value.length){ return; }
				activeVersionId.value = versions.value[0]._id;

			}
			catch(err){}

		};

		watch(activeAssetId, async () => {
			activeVersionId.value = null;

			if(activeAssetId.value){
				versions.value = await AssetAPI.loadVersionList(activeAssetId.value);
			}

			setTimeout(() => {

				if(!activeVersionId.value){
					useLatest();
				}

			},10);
		});

		watch(activeVersionId, async (v) => {

			if(!v || !activeAssetId.value){
				activeVersion.value = null;
				return;
			}

			const aid = activeAssetId.value;

			try {
				activeVersion.value = await AssetAPI.getAssetVersion(aid, v, "+data +vIndex");
			}
			catch(err){}
		});



		return {
			activeAsset,
			canConfirm,
			assetType,
			activeVersion,
			activeAssetId,
			activeVersionId,
			showHistory,
			modal,
			hide,
			open,
			submit,
			translate,
			usingLatest,
			tag,
		};
	}
});

