import { defineComponent, ref, watch, inject } from '@vue/composition-api';
import * as AssetAPI from '@/services/api/assets';
import { AssetVersion } from '@/psychlab/types';
import { PreviewAssetData } from '@/components.assets/preview-asset-data';
import { AssetContext } from '@/components.assets/manage-asset';
import { AbsBox } from '@ui';

export const ViewAsset = defineComponent({
  components: {
    PreviewAssetData,
    AbsBox,
  },
  setup() {
    const assetCtx = inject<AssetContext>('assetContext');
    if (!assetCtx) {
      throw Error('View Asset: Asset Context missing!');
    }

    const { assetId, asset } = assetCtx;

    const latestVersion = ref<AssetVersion<any>>();

    const init = async () => {
      latestVersion.value = await AssetAPI.getAssetVersion(
        assetId.value,
        'latest',
        '+data +vIndex',
      );
      refreshPageTitle();
    };

    const refreshPageTitle = () => {
      if (!asset.value) {
        return;
      }
      document.title = `${asset.value.name}`;
    };

    init();

    watch(() => asset.value?.lastModified, init);

    watch(asset, refreshPageTitle);

    return {
      asset,
      latestVersion,
    };
  },
  render() {
    if (!this.asset || !this.latestVersion) {
      return <div />;
    }

    return (
      <AbsBox style="overflow:hidden">
        <PreviewAssetData
          data={this.latestVersion.data}
          type={this.asset.type}
          version={this.latestVersion._id}
        />
      </AbsBox>
    );
  },
});
