












































// vendor
import { defineComponent, ref } from '@vue/composition-api';
// project
import { generateGUID } from '@/utils/guid';
// local
import { ContextOption } from './ContextOption';

type ContextMenu = {
  open(e: any, data: any): void;
  close(): void;
};

const optionSort = (a: ContextOption, b: ContextOption) => {
  return (a.order || 0) - (b.order || 0);
};

export default defineComponent({
  setup() {
    const contextMenu = ref<ContextMenu>();

    const open = (e: any, options: ContextOption[]) => {
      options.sort(optionSort);

      const checked = options.findIndex(o => o.checked !== undefined) > -1;

      setTimeout(() => (contextMenu as any).value?.open(e, { options, checked }), 1);
    };

    const getTextVariant = (bgVariant: string) => {
      return (
        (
          {
            light: 'dark',
          } as any
        )[bgVariant || 'light'] || 'light'
      );
    };

    const onExitMenu = () => {
      contextMenu.value?.close();
    };

    const useSeparator = (i: number, options: ContextOption[]) => {
      if (i >= options.length - 1) {
        return false;
      }

      const oa = options[i].order || 0;
      const ob = options[i + 1].order || 0;

      return oa !== ob;
    };

    return {
      contextMenu,
      open,
      onExitMenu,
      getTextVariant,
      useSeparator,
      generateGUID,
    };
  },
});
