var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AbsBox',[_c('RenderFormBG',{attrs:{"theme":_vm.activeTheme}}),_c('AbsBox',{staticClass:"d-flex flex-row"},[(_vm.listOpen)?_c('div',{staticClass:"prel border-right border-dark",staticStyle:{"width":"15em"}},[_c('SectionList',{attrs:{"data":_vm.data},on:{"add-section":_vm.addSection,"delete-section":_vm.deleteSection,"duplicate-section":_vm.duplicateSection,"toggle":_vm.toggleList},model:{value:(_vm.activeId),callback:function ($$v) {_vm.activeId=$$v},expression:"activeId"}})],1):_vm._e(),_c('div',{staticClass:"flex-fill d-flex flex-column",staticStyle:{"position":"relative"}},[_c('EditorNav',{scopedSlots:_vm._u([{key:"left",fn:function(){return [(!_vm.listOpen)?_c('SectionToggle',{attrs:{"data":_vm.data},on:{"add-section":_vm.addSection,"toggle":_vm.toggleList},model:{value:(_vm.activeId),callback:function ($$v) {_vm.activeId=$$v},expression:"activeId"}}):_vm._e()]},proxy:true},{key:"right",fn:function(){return [_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
              interactive: false,
              title: _vm.translate('forms.label.previewMode'),
            }),expression:"{\n              interactive: false,\n              title: translate('forms.label.previewMode'),\n            }",modifiers:{"hover":true}}],staticClass:"control-btn preview-btn",class:{ active: !_vm.showControls },on:{"click":function($event){_vm.showControls = !_vm.showControls}}},[_c('Icon',{attrs:{"name":("mdi." + (_vm.showControls ? 'eye' : 'eye'))}})],1),(!(_vm.editTheme || _vm.activeTab !== 'form'))?_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
              interactive: false,
              delay: 0,
              title: _vm.translate('forms.label.themes'),
            }),expression:"{\n              interactive: false,\n              delay: 0,\n              title: translate('forms.label.themes'),\n            }",modifiers:{"hover":true}}],staticClass:"theme-toggle",attrs:{"disabled":_vm.editTheme || _vm.activeTab !== 'form',"data-cy":"themes-open-btn"},on:{"click":function($event){_vm.editTheme = !_vm.editTheme}}},[_c('Icon',{attrs:{"name":"mdi.palette"}}),_c('Icon',{attrs:{"name":"mdi.chevron-right"}})],1):_vm._e()]},proxy:true}]),model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}}),(!_vm.showControls && _vm.activeTab === 'form')?_c('div',{staticClass:"bg-info d-flex border-bottomx border-secondary",staticStyle:{"margin-top":"1px"}},[_c('small',{staticClass:"text-light m-auto"},[_vm._v(" "+_vm._s(_vm.translate('forms.message.previewing'))+" ")])]):_vm._e(),_c('div',{staticClass:"border-bottom border-secondary"}),_c('div',{staticClass:"flex-fill prel"},[(_vm.activeTab === 'form')?[_c('AbsBox',{staticClass:"d-flex"},[_c('div',{staticClass:"flex-fill",staticStyle:{"position":"relative"}},[_c('AbsBox',{staticClass:"scroll"},[_c('Container',{staticClass:"p-0"},[(_vm.activeSection)?_c('EditFormSection',{key:_vm.activeSection.id,attrs:{"showControls":_vm.showControls,"activeItem":_vm.activeItemId,"section":_vm.activeSection,"allowDrag":Boolean(_vm.activeSection),"info":{ index: _vm.activeSectionIndex, count: _vm.sectionCount }}}):_vm._e()],1)],1)],1),(_vm.editTheme)?_c('ThemeInspector',{attrs:{"data":_vm.data,"activeTheme":_vm.activeThemeProfile},on:{"toggle":function($event){_vm.editTheme = !_vm.editTheme},"select-theme":function($event){_vm.activeThemeProfile = $event}}}):_vm._e()],1)]:_vm._e(),(_vm.activeTab === 'flow')?[_c('AbsBox',{staticClass:"scroll"},[_c('Container',{staticClass:"p-0"},[_c('EditFlow',{attrs:{"data":_vm.data,"activeSelection":_vm.activeId}})],1)],1)]:_vm._e()],2)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }