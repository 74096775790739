// import { ContextOption, ContextConfig } from "@/components.generic/context-menu";
import { context } from '@/AppModal';

export const openContextMenu = (e: Event, options: ContextOption[], config?: ContextConfig) => {
  context(e, options, config);
};

type ContextOption = {
  'name': string;
  'variant'?: string;
  'icon'?: string;
  'order'?: number;
  'checked'?: boolean;
  'fn': Function;
  'disabled'?: boolean;
  'data-cy'?: string;
};

type ContextConfig = {};

export type { ContextOption, ContextConfig };
