type BasicFn = () => void;

type Config = {
  onTitle: BasicFn;
  onBody: BasicFn;
};

export interface IFormEditor extends Config {}

export const useFormEditor = (c: Config): IFormEditor => {
  return {
    ...c,
  };
};
