var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"prel border-left border-secondary bg-light",staticStyle:{"width":"15em"},attrs:{"data-cy":"themes"}},[_c('AbsBox',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"p-2 border-bottom border-secondary d-flex"},[_c('button',{staticClass:"list-toggle-btn",on:{"click":function($event){return _vm.$emit('toggle')}}},[_c('h6',{staticClass:"my-auto"},[_c('Icon',{attrs:{"name":"mdi.chevron-down"}}),_vm._v(" "+_vm._s(_vm.translate('forms.label.themes'))+" ")],1)]),_c('div',{staticClass:"mx-auto"}),_c('PlusButton',{on:{"click":_vm.addTheme}})],1),(_vm.data.themeProfiles && _vm.data.themeProfiles.length)?_c('div',{staticClass:"p-2 border-bottom border-secondary"},[_c('ProfileList',_vm._g({attrs:{"data":_vm.data,"activeTheme":_vm.activeTheme}},_vm.$listeners))],1):_vm._e(),(_vm.profile)?_c('div',{staticClass:"p-2 border-bottom border-secondary d-flex"},[_c('div',{staticClass:"mr-auto ml-2"},[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
            interactive: false,
            title: _vm.translate('forms.action.renameTheme'),
          }),expression:"{\n            interactive: false,\n            title: translate('forms.action.renameTheme'),\n          }",modifiers:{"hover":true}}],staticClass:"ico-btn rename-btn",on:{"click":_vm.renameProfile}},[_c('Icon',{attrs:{"name":"mdi.pencil"}})],1),_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
            interactive: false,
            title: _vm.translate('forms.action.deleteTheme'),
          }),expression:"{\n            interactive: false,\n            title: translate('forms.action.deleteTheme'),\n          }",modifiers:{"hover":true}}],staticClass:"ico-btn delete-btn",on:{"click":_vm.deleteProfile}},[_c('Icon',{attrs:{"name":"mdi.trash-can"}})],1),_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
            interactive: false,
            title: _vm.translate('forms.action.duplicateTheme'),
          }),expression:"{\n            interactive: false,\n            title: translate('forms.action.duplicateTheme'),\n          }",modifiers:{"hover":true}}],staticClass:"ico-btn copy-btn",on:{"click":_vm.duplicateProfile}},[_c('Icon',{attrs:{"name":"mdi.content-duplicate"}})],1)]),_c('div',{staticClass:"mx-auto"}),_c('button',{staticClass:"default-btn",attrs:{"disabled":_vm.data.defaultThemeProfile === _vm.activeTheme},on:{"click":_vm.setDefault}},[_c('small',[_vm._v(" "+_vm._s(_vm.translate('forms.action.setDefault'))+" ")])])]):_vm._e(),_c('div',{staticClass:"flex-fill",staticStyle:{"position":"relative"}},[_c('AbsBox',{staticClass:"p-3x",staticStyle:{"overflow":"auto"}},[(_vm.profile)?_c('EditTheme',{attrs:{"profile":_vm.profile}}):_vm._e()],1)],1),_c('div',{staticClass:"p-2 border-top border-secondary"},[_c('input',{ref:"copyInput",staticStyle:{"position":"absolute","pointer-events":"none","opacity":"0"}}),_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
          interactive: false,
          title: _vm.translate('forms.action.copySettings'),
          html: true,
        }),expression:"{\n          interactive: false,\n          title: translate('forms.action.copySettings'),\n          html: true,\n        }",modifiers:{"hover":true}}],staticClass:"ico-btn",on:{"click":_vm.copyTheme}},[_c('Icon',{attrs:{"name":"mdi.content-copy"}})],1),_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
          interactive: false,
          title: _vm.translate('forms.action.pasteSettings'),
          html: true,
        }),expression:"{\n          interactive: false,\n          title: translate('forms.action.pasteSettings'),\n          html: true,\n        }",modifiers:{"hover":true}}],staticClass:"ico-btn",on:{"click":_vm.pasteTheme}},[_c('Icon',{attrs:{"name":"mdi.content-paste"}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }