// project
import type { NodeTemplate } from '@/components.edit/edit-graph';

// local
import EditBlueprint from './vue/edit-blueprint.vue';
import * as Templates from './ts/templates';

const NodeTemplates: { [k: string]: NodeTemplate<any> } = Templates;

export { NodeTemplate, EditBlueprint, NodeTemplates };
