



























































































import { defineComponent, computed, PropType, set } from "@vue/composition-api";
import { ItemBox, getBaseProps, ItemDivider, ErrorOverlay } from "../shared";
import { Container, Row, Col, AbsBox } from "@ui";
import { computeScaleSizing } from "./utils";
import { EditLikert } from "../edit-likert";

export default defineComponent({
	emits:[ "input", "row" ],
	props:{
		value:{
			type:Array as PropType<number[]>,
			default:() => [],
		},
		columns:{
			type:Array as PropType<string[]>,
			default:0,
		},
		rows:{
			type:Array as PropType<string[]>,
			default:() => [],
		},
		invalidRows:{
			type:Array as PropType<number[]>,
			default:() => [],
		},
		...getBaseProps()
	},
	components:{
		Row,
		AbsBox,
		Col,
		ItemDivider,
		Container,
		ItemBox,
		ErrorOverlay,
		EditLikert,
	},
	setup(props, ctx){

		const val = computed({
			get:() => props.value,
			set:v => ctx.emit("input", v)
		});

		const radioCols = computed(() => {
			const l = sizing.value.labelCols.l + sizing.value.labelCols.r;
			return 12 - l;
		});

		const getLeftLabel = (row:string) => {
			if(!row){ return ""; }
			const s = row.split(";");
			if(s.length){ return s[0].trim(); }
			return "";
		};

		const getRightLabel = (row:string) => {
			if(!row){ return ""; }
			const s = row.split(";");
			if(s.length > 1){ return s[1].trim(); }
			return "";
		};

		const emptyHeader = computed(() => {
			return !props.columns.length || !props.columns.find(c => c.length > 0)
		});

		const sizing = computed(() => computeScaleSizing(props.rows, props.columns));

		const isPicked = (r:number, c:number) => {
			if(!val.value){ return false; }
			if(r < 0 || r >= val.value.length){ return false; }
			return val.value[r] === c;
		};

		const pickColumn = (row:number, col:number) => {
			if(val.value.length !== props.rows.length){
				props.rows.forEach((r, i) => set(val.value, i, -1));
			}
			set(val.value, row, col);
			ctx.emit("row", row);
		};

		const rowHasError = (i:number) => {
			return props.invalidRows.findIndex(ri => ri === i) > -1;
		};

		const hoverBoxStyle = computed(() => {
			return {
				backgroundColor:props.theme["input.likert.hover.color"],
			}
		});

		const radioStyle = computed(() => {
			return {
				backgroundColor:props.theme["input.likert.hover.color"],
			}
		});

		const getRadioStyle = (active:boolean) => {

			const shared = {
				borderWidth:"1px",
				borderStyle:"solid",
				width:`${sizing.value.buttons}em`,
				height:`${sizing.value.buttons}em`,
			};

			if(active){
				return {
					...shared,
					borderColor:props.theme["input.likert.selected.color"] || "#ffffff",
					backgroundColor:props.theme["input.likert.selected.color"] || "#ffffff",
				}
			}
			return {
				...shared,
				borderColor:props.theme["input.likert.border.color"]  || "#ffffff"
			}

		};

		return {
			val,
			sizing,
			isPicked,
			pickColumn,
			rowHasError,
			hoverBoxStyle,
			radioStyle,
			getRadioStyle,
			emptyHeader,
			radioCols,
			getLeftLabel,
			getRightLabel
		};
	}
});
