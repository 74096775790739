import { defineComponent, computed } from '@vue/composition-api';
import { getBuildInfo } from '@config';

const info = {
  title: 'psychlab',
};

export const ProductName = defineComponent({
  render() {
    return (
      <span class="text-decoration-none" style="font-family:'NATS'">
        {info.title}
      </span>
    );
  },
});

export const ProductVersion = defineComponent({
  setup() {
    const version = computed(() => getBuildInfo().version);

    const displayVersion = computed(() => {
      const { hostname } = window.location;

      const env = process.env.NODE_ENV;
      const envString =
        env !== 'production'
          ? `-${env}`
          : hostname.startsWith('psychlab-dev')
          ? ' dev'
          : hostname.startsWith('psychlab-alpha')
          ? ' alpha'
          : '';

      return `v${version.value}${envString}`;
    });

    return {
      displayVersion,
    };
  },
  render() {
    return <small style="font-family:'NATS'">{this.displayVersion}</small>;
  },
});
