import { defineComponent, ref, reactive, watch, computed } from '@vue/composition-api';
import { matchEmail } from '@utils/text';
import { useTranslate } from '@lang';
import { Icon } from '@ui';
import { Provide } from '@api/auth';

enum ErrorType {
  EmptyEmail = 'auth.error.emailIsRequired',
  InvalidEmail = 'auth.error.invalidEmail',
  EmptyPassword = 'auth.error.passwordIsRequired',
  InvalidCredentials = 'auth.error.invalidCredentials',
}

type FormData = {
  email: string;
  password: string;
};

type FormValidator = (d: FormData) => string | null;

const validators: FormValidator[] = [
  d => (!d.email ? ErrorType.EmptyEmail : null),
  d => (!d.password ? ErrorType.EmptyPassword : null),
  d => (!matchEmail(d.email) ? ErrorType.InvalidEmail : null),
];

const validateForm = (d: FormData) => {
  for (let i = 0; i < validators.length; i++) {
    const e = validators[i](d);
    if (e) {
      return e;
    }
  }
  return null;
};

export const LoginForm = defineComponent({
  setup(_, context) {
    const error = ref<string | null>(null);
    const formData = reactive<FormData>({ email: '', password: '' });

    watch(formData, () => (error.value = null));

    const submit = async (e: Event) => {
      e.preventDefault();

      error.value = validateForm(formData);

      if (error.value) {
        return;
      }

      try {
        const { token } = await Provide.userAuth.authenticateWithEmail(formData);
        context.root.$store.dispatch('saveAuth', { token });
        context.root.$router.push('/');
      } catch (err) {
        error.value = ErrorType.InvalidCredentials;
      }
    };

    return {
      error,
      formData,
      submit,
    };
  },
  render() {
    const err = this.error ? <LoginError msg={this.error} /> : <div />;

    return (
      <FormBox>
        <form v-on:submit={this.submit}>
          <PaddedBox>
            <Email v-model={this.formData.email} />
            <div class="mb-3" />
            <Password v-model={this.formData.password} />
          </PaddedBox>
          <Divider />
          <PaddedBox class="d-flex justify-content-end">
            <LoginButton />
          </PaddedBox>
          {err}
        </form>
      </FormBox>
    );
  },
});

const FormBox = defineComponent({
  render() {
    return <div class="bg-dark shadow">{this.$slots.default}</div>;
  },
});

const PaddedBox = defineComponent({
  render() {
    return <div class="p-3">{this.$slots.default}</div>;
  },
});

const LoginError = defineComponent({
  props: ['msg'],
  setup(props) {
    const { translate } = useTranslate();
    return {
      text: computed(() => translate(props.msg || '')),
    };
  },
  render() {
    return (
      <div class="bg-danger text-light p-3" data-cy="login-error-msg">
        <Icon name="alert-outline" /> {this.text}
      </div>
    );
  },
});

const Email = defineComponent({
  props: { value: String },
  setup(props, ctx) {
    return {
      val: computed({
        get: () => props.value,
        set: v => ctx.emit('input', v),
      }),
    };
  },
  render() {
    return (
      <input
        id="email-field"
        v-model={this.val}
        placeholder="Email"
        autocomplete="off"
        class="form-control"
        data-cy={'email-field'}
      />
    );
  },
});

const Password = defineComponent({
  props: { value: String },
  setup(props, ctx) {
    return {
      val: computed({
        get: () => props.value,
        set: v => ctx.emit('input', v),
      }),
    };
  },
  render() {
    return (
      <input
        id="pass-field"
        placeholder="Password"
        v-model={this.val}
        type="password"
        class="form-control"
        data-cy={'password-field'}
      />
    );
  },
});

const LoginButton = defineComponent({
  render() {
    return (
      <button type="submit" class="btn btn-info rounded-0 shadow-none" data-cy="login-button">
        <Icon name="login" />
      </button>
    );
  },
});

const Divider = defineComponent({
  render() {
    return <hr class="bg-secondary m-0" />;
  },
});
