























































import { defineComponent, computed, ref, PropType, watch, reactive } from "@vue/composition-api";
import { GraphData } from "../ts/types/GraphData";
import { default as NodeInfoButtons } from "./node-info-buttons.vue";

type Dictionary<T> = { [k:string]:T };

type Vector2D = { x:number; y:number; }

export const formatTransform = (offset:Vector2D, zoom:number) => {
	return `translate(${offset.x}px,${offset.y}px) scale(${zoom}, ${zoom})`;
}
export default defineComponent({

	emits:[
		"open-node",
		"update-node"
	],
	props:{
		graph:{
			type:Object as PropType<GraphData>,
			required:true
		},
		zoom:{
			type:Number,
			default:1
		},
		drag:{
			type:Object as PropType<Vector2D>,
			default:() => ({ x:0, y:0 })
		},
		positions:{
			type:Object as PropType<Dictionary<Vector2D>>,
			default:() => ({})
		},
		// labels:{
		// 	type:Object as PropType<Dictionary<string>>,
		// 	default:() => ({})
		// }
	},
	components:{
		NodeInfoButtons
	},
	setup(props, context){

		const nodeRadius = ref(50);


		const dragOffset = reactive({
			x:props.drag.x,
			y:props.drag.y
		});


		const startNode = computed(() => {

			const ids = Object.keys(props.graph.nodes);
			if(ids.length === 0){ return null; }

			let current = ids[0];

			ids.forEach(k => {

				const cn = props.graph.nodes[current];
				const n = props.graph.nodes[k];

				if(n.x < cn.x){
					current = k;
				}

			});
			return current;
		});


		const displayNodes = computed(() => {
			return Object.keys(props.graph.nodes)
			.map(k => {
				const node = props.graph.nodes[k];
				return {
					id:k,
					data:node,
					...props.positions[k],
					// label:node.name ? node.name : props.labels[k]
				};
			});

		});

		const canvasTransform = computed(() => {
			return formatTransform(props.drag, props.zoom);
		});

		const rootStyle = computed(() => {
			return {
				transform:canvasTransform.value
			}
		});

		const openNode = (node:string) => {
			context.emit("open-node", node);
		};

		return {
			startNode,
			displayNodes,
			canvasTransform,
			rootStyle,
			nodeRadius,
			openNode
		};
	}
});

