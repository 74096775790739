import { render, staticRenderFns } from "./editor-nav.vue?vue&type=template&id=16acb002&scoped=true&"
import script from "./editor-nav.vue?vue&type=script&lang=ts&"
export * from "./editor-nav.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16acb002",
  null
  
)

export default component.exports