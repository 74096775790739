var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AbsBox',{staticClass:"d-flex p-3 flex-columnx bg-light"},[_c('div',{staticClass:"mr-auto mx-auto m-auto",attrs:{"data-cy":"flow-editor"}},[_c('div',{staticClass:"d-flex flex-column mb-1",staticStyle:{"opacity":"0.7"}},[_c('h5',{staticClass:"mx-auto mb-0"},[_vm._v(" "+_vm._s(_vm.translate("forms.label.sections"))+" ")])]),_c('div',{staticClass:"d-flex flex-column mb-x5"},[_c('ShuffleButton',{staticClass:"mx-auto",attrs:{"value":_vm.getFormShuffle()},on:{"input":function($event){return _vm.setFormShuffle($event)}}})],1),_c('VEdge'),_c('div',{staticClass:"d-flex"},_vm._l((_vm.data.sections),function(section,si){return _c('div',{key:section.id,staticClass:"section-area select-item  mb-autox mx-2x px-2x py-2x",class:{ active:_vm.activeSelection === ("s:" + (section.id)) },attrs:{"data-cy":("section-" + si)}},[_c('HEdge',{attrs:{"first":si === 0,"last":si === _vm.data.sections.length - 1}}),_c('VEdge'),_c('div',{staticClass:"d-flex mb-2 px-2"},[_c('small',{staticClass:"mx-auto"},[_vm._v(" "+_vm._s(_vm.getSectionTitle(section))+" ")])]),_c('div',{staticClass:"d-flex"},[_c('ShuffleButton',{staticClass:"mx-auto",attrs:{"value":_vm.getSectionShuffle(section)},on:{"input":function($event){return _vm.setSectionShuffle(section, $event)}}})],1),_c('EdgeTree',{attrs:{"vcount":section.items.length,"height":2}}),_c('div',{staticClass:"d-flex justify-content-center mx--3"},_vm._l((section.items),function(item,itemInx){return _c('div',{key:item.id,staticClass:"select-item d-flex flex-column",class:{
							active:_vm.activeSelection === ("i:" + (item.id)),

						},attrs:{"data-cy":("section-item-" + itemInx)}},[_c('PlatformButton',{staticClass:"mx-auto",model:{value:(item.platforms),callback:function ($$v) {_vm.$set(item, "platforms", $$v)},expression:"item.platforms"}}),_c('VEdge'),_c('OptionalButton',{staticClass:"mx-auto",attrs:{"xvalue":"!item.optional","xinput":"item.optional = !$event","disabled":!_vm.isValueType(item.type)},model:{value:(item.optional),callback:function ($$v) {_vm.$set(item, "optional", $$v)},expression:"item.optional"}}),_c('VEdge'),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
								interactive:false,
								title:_vm.getItemTooltip(item),
								html:true,
								delay:25,
								placement:'bottom'
							}),expression:"{\n\t\t\t\t\t\t\t\tinteractive:false,\n\t\t\t\t\t\t\t\ttitle:getItemTooltip(item),\n\t\t\t\t\t\t\t\thtml:true,\n\t\t\t\t\t\t\t\tdelay:25,\n\t\t\t\t\t\t\t\tplacement:'bottom'\n\t\t\t\t\t\t\t}",modifiers:{"hover":true}}],staticClass:"d-flex item-node",class:{
								optional:Boolean(item.optional),
							}},[_c('Icon',{staticClass:"m-auto",attrs:{"name":_vm.getItemIcon(item.type)}})],1)],1)}),0)],1)}),0)],1),_c('AbsBox',{staticClass:"p-3 d-flex",staticStyle:{"pointer-events":"none"}},[_c('Legend',{staticClass:"mr-auto mb-auto"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }