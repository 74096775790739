





























import { defineComponent, computed, ref } from '@vue/composition-api';
import { ToolbarButton, Icon } from '@ui';
import { useTranslate } from '@lang';

export default defineComponent({
  emits: ['input'],
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  components: {
    ToolbarButton,
    Icon,
  },
  setup() {
    const { translate } = useTranslate();

    const tabs = computed(() => {
      return [
        {
          label: translate('forms.label.content'),
          value: 'form',
          // icon:"mdi.file-document"
        },
        {
          label: translate('forms.label.flow'),
          value: 'flow',
          // icon:"mdi.graph"
        },
        // {
        // 	label:translate("forms.label.theme"),
        // 	value:"theme",
        // 	// icon:"mdi.palette",
        // 	// disabled:true,
        // },
      ];
    });

    return {
      tabs,
    };
  },
});
