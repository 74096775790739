export const Port = {
	label: {
		text: {
			style: {
				"font-style": "italic",
				"stroke": "transparent",
				"fill": "#6b6b6b",
				"font-size":"0.8em",
				"cursor": "default"
			},
			attributes: {
				"dominant-baseline": "middle"
			}
		}
	},
	background: {
		collider: {
			attributes: {
				r: 15,
				fill: "transparent",
			}
		},
		shape: {
			attributes: {
				r: 8,
				fill: "white",
				// fill:"#343a40",
				// fill:"#6c757d",
				stroke: "transparent",
				// stroke: "white",
				strokeWidth: 1
			}
		}
	}

};
