import { PhraseDictionary } from '../types';
import { mergeDictionaries } from '../mergeDictionaries';

const label: PhraseDictionary = {
  noAuthSet: {
    en: 'None',
    is: 'Ekkert',
  },
};

const action: PhraseDictionary = {};

const message: PhraseDictionary = {};

const error: PhraseDictionary = {
  emailIsRequired: {
    en: 'Email is required',
    is: 'Það vantar netfang',
  },
  invalidEmail: {
    en: 'Invalid email',
    is: 'Netfang er ekki rétt',
  },
  passwordIsRequired: {
    en: 'Password is required',
    is: 'Það vantar lykilorð',
  },
  invalidCredentials: {
    en: 'Invalid credentials',
    is: 'Netfang og lykilorð passa ekki saman',
  },
};

export default mergeDictionaries('auth', { label, action, message, error });
