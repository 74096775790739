import { defineComponent, ref, computed, SetupContext } from '@vue/composition-api';
import { AssetList, AssetGrid } from '@components/assets';
import { Asset } from '@/psychlab/types';
import { useTags } from '@/hooks/useTags';
import { TagSidebar, TagRouteToggle } from '@components/tags';
import { ContextHeader } from '@components/nav';
import { AbsBox, SearchFilter, Container, Heading } from '@ui';
import { useTranslate } from '@lang';
import * as AppModal from '@/AppModal';
import * as StudyAPI from '@/services/api/studies';
import { default as Router } from 'vue-router';

const FlexFill = defineComponent({
  template: `
	<div class="flex-fill" style="position:relative;"><slot/></div>
	`,
});

enum Translations {
  AboutBlueprints = 'assets.message.aboutBlueprints',
  Blueprints = 'assets.label.studyBlueprints',
  Elements = 'assets.label.studyElements',
  AboutElements = 'assets.message.aboutElements',
  Assets = 'assets.label.assets',
  FilterAssets = 'assets.action.filterAssets',
  CreateStudy = 'studies.action.createStudy',
}

enum ActionIcons {
  Rename = 'pencil-box',
  EditColor = 'palette',
  CreateStudy = 'rocket mdi-rotate-45',
}

enum RouteNames {
  StudyDashboard = 'Run.Group',
}

const openStudyDashboard = (id: string, r: Router) => {
  r.push({
    name: RouteNames.StudyDashboard,
    params: {
      groupId: id,
    },
  });
};

export default defineComponent({
  beforeRouteUpdate(to: any, _, next: any) {
    // note: hack until vue-router uses composition api
    const t = to.query['t'];
    if (this.currentTagId !== t) {
      this.setCurrentTag(t);
    }
    next();
  },
  setup(_, context) {
    const router = context.root.$router;

    const searchFilter = ref(getQueryValue('filter', context, ''));

    const { translate } = useTranslate();

    const { sortedTags } = useTags();

    const currentTagId = ref<string | null>(null);

    const currentTag = computed(() => {
      return sortedTags.value.find(t => t._id === currentTagId.value);
    });

    const setCurrentTag = (v: string) => {
      currentTagId.value = v;
    };

    const init = async () => {
      const t = context.root.$route.query['t'];
      if (t && typeof t === 'string') {
        currentTagId.value = t;
      }
    };

    const getAssetTagArgs = (asset: Asset) => {
      return {
        type: 'asset',
        item: asset._id,
      };
    };

    const tagDeleted = (tag: string) => {
      if (currentTagId.value === tag) {
        currentTagId.value = null;
      }
    };

    const saveSearch = () => {
      const sv = searchFilter.value.length > 0 ? searchFilter.value : undefined;
      setQueryValue('filter', sv, context);
    };

    const clearSearch = () => {
      searchFilter.value = '';
      setQueryValue('filter', undefined, context);
    };

    const createStudy = (blueprint: string) => {
      AppModal.createStudy(
        {
          blueprint,
        },
        async r => {
          const { name, study, version, language, openDashboard, auth } = r;
          const s = await StudyAPI.createStudy({
            name,
            blueprint: `${study}@${version}`,
            language,
            auth,
          });
          if (openDashboard) {
            openStudyDashboard(s._id, router);
          }
        },
      );
    };

    const getContextOptions = (a: { _id: string }): any[] => {
      return [
        {
          name: translate(Translations.CreateStudy),
          icon: ActionIcons.CreateStudy,
          fn: () => createStudy(a._id),
          order: -1,
        },
      ];
    };

    init();

    return {
      currentTagId,
      currentTag,
      searchFilter,
      phrases: computed(() => ({
        assets: translate(Translations.Assets),
        filterAssets: translate(Translations.FilterAssets),
        aboutBlueprints: translate(Translations.AboutBlueprints),
        blueprints: translate(Translations.Blueprints),
        elements: translate(Translations.Elements),
        aboutElements: translate(Translations.AboutElements),
      })),
      setCurrentTag,
      getAssetTagArgs,
      tagDeleted,
      clearSearch,
      saveSearch,
      getContextOptions,
    };
  },
  render() {
    const tag = <AbsBox class="pl-3 d-flex">{/* <TagRouteToggle/> */}</AbsBox>;

    return (
      <AbsBox class="d-flex" style="overflow:hidden">
        <ContextHeader>
          <AbsBox class="d-flex">
            <FlexFill>
              <AbsBox class="d-flex pl-3">
                <Heading size={3} class="my-auto">
                  {this.phrases.assets}
                </Heading>
              </AbsBox>
            </FlexFill>
            <FlexFill>
              <AbsBox class="d-flex justify-content-end">
                <TagRouteToggle class="my-auto mr-2" />

                <SearchFilter
                  v-model={this.searchFilter}
                  placeholder={this.phrases.filterAssets + '...'}
                  v-on:blur={this.saveSearch}
                  v-on:cleared={this.clearSearch}
                  class="ml-autxo my-auto mr-3"
                />
              </AbsBox>
            </FlexFill>
          </AbsBox>
        </ContextHeader>

        <TagSidebar v-on:tag-deleted={this.tagDeleted} />

        <FlexFill>
          <AbsBox class="pt-3" style="overflow:auto">
            <Container>
              <AssetGrid
                contextFn={this.getContextOptions}
                include={['Graph']}
                defaultTag={this.currentTagId || undefined}
                searchFilter={this.searchFilter}
                title={this.phrases.blueprints}
                titleTip={this.phrases.aboutBlueprints}
              />
            </Container>

            <div class="mb-5" />

            <Container>
              <AssetList
                exclude={['Graph']}
                defaultTag={this.currentTagId || undefined}
                searchFilter={this.searchFilter}
                title={this.phrases.elements}
                titleTip={this.phrases.aboutElements}
              />
            </Container>
            <div class="mb-2" />
          </AbsBox>
        </FlexFill>
      </AbsBox>
    );
  },
});

const setQueryValue = (k: string, v: any, context: SetupContext) => {
  const q: any = { ...context.root.$route.query };
  if (v !== undefined && v !== null) {
    q[k] = v;
  } else {
    delete q[k];
  }
  context.root.$router.push({ query: q }).catch(() => {});
};

const getQueryValue = (k: string, context: SetupContext, def: string): string => {
  return (context.root.$route.query[k] as any as string) || def;
};
