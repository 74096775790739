import { defineComponent, computed, PropType, reactive } from '@vue/composition-api';
import * as SessionAPI from '@/services/api/sessions';
import { Session } from '@/psychlab/types';
import { RunNode } from './RunNode';
import { setSessionCompleted } from '@/services/api/sessions';

export const RunGraph = defineComponent({
  components: {
    RunNode,
  },
  props: {
    session: {
      type: Object as PropType<Session>,
      required: true,
    },
  },
  setup(props) {
    const sessionId = computed(() => props.session._id);

    const sessionState = computed({
      get: () => props.session.state,
      set: s => (props.session.state = s),
    });

    const nodePath = reactive(props.session.path);
    const nodes = reactive((props.session.graph as any).nodes);

    const onFirstNode = computed(
      () => nodePath.findIndex(n => n === sessionState.value.node) === 0,
    );
    const onLastNode = computed(
      () => nodePath.findIndex(n => n === sessionState.value.node) === nodePath.length - 1,
    );

    const currentNodeId = computed(() => sessionState.value.node);

    const currentNode = computed(() => (currentNodeId.value ? nodes[currentNodeId.value] : null));

    const finished = computed(() => sessionState.value.node === null);

    const gotoPrevious = () => {
      const ci = nodePath.findIndex(n => n === sessionState.value.node);
      const next = nodePath[ci - 1];
      openNode(next);
    };

    const gotoNext = () => {
      const ni = nodePath.findIndex(n => n === sessionState.value.node) + 1;
      if (ni === nodePath.length) {
        finish();
      } else {
        openNode(nodePath[ni]);
      }
    };

    const finish = () => {
      openNode(null);
      // mark session as completed - this can be tested in OpenSurvey component
      SessionAPI.setSessionCompleted({ sessionId: sessionId.value });
    };

    const openNode = async (node: string | null) => {
      sessionState.value = await SessionAPI.modifySessionState(sessionId.value, { node });
    };

    return {
      onFirstNode,
      onLastNode,
      currentNodeId,
      currentNode,
      finished,
      gotoPrevious,
      gotoNext,
    };
  },
  render() {
    if (!this.currentNode || !this.currentNodeId) {
      return <div />;
    }

    return (
      <RunNode
        key={this.currentNodeId}
        nodeId={this.currentNodeId}
        node={this.currentNode}
        showPrevious={!this.onFirstNode}
        isLast={this.onLastNode}
        v-on:previous={this.gotoPrevious}
        v-on:next={this.gotoNext}
      />

      // <RunNode

      // @previous="gotoPrevious"
      // @next="gotoNext"
      // />
    );
  },
});
