






import { defineComponent, computed, ref } from '@vue/composition-api';
import { AbsBox } from '@ui';

export default defineComponent({
  emits: ['click'],
  props: ['data-cy'],
  components: {
    AbsBox,
  },
  setup(props, ctx) {
    const onclick = (e: Event) => {
      ctx.emit('click', e);
    };
    return {
      onclick,
    };
  },
});
