







































// vendor
import { defineComponent, PropType, computed } from "@vue/composition-api";
// local
import * as NodeWindows from "./windows";

import { useTranslate } from "@lang";
import { Icon } from "@ui";

export default defineComponent({

	emits:[ "close" ],
	props:{
		nodeId:{
			type:String,
			required:true
		},
		type:{
			type:String,
			required:true
		},
		parameters:{
			type:Object as PropType<any>,
			required:true
		}
	},
	components:{
		Icon,
	},
	setup(props, context){

		const component = computed(() => (NodeWindows as any)[props.type]);

		const { translate } = useTranslate();

		const back = () => context.emit("close");

		return {
			component,
			back,
			translate,
		};
	}

});



