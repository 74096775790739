import { defineComponent } from '@vue/composition-api';
import { RunExternal } from '@/components.run/run-external';
import { getProps } from './getProps';

type FormParams = {};

export const External = defineComponent({
  emits: ['end', 'previous'],
  props: {
    ...getProps<FormParams>(),
  },
  setup(_, context) {
    return {
      onEnd: () => context.emit('end'),
      onBack: () => context.emit('previous'),
    };
  },
  render() {
    const form = this.node?.parameters as any;

    return (
      <RunExternal
        dataNamespace={this.nodeId || ''}
        data={form}
        showBack={this.showBack}
        isLast={this.isLast}
        v-on:back={this.onBack}
        v-on:end={this.onEnd}
      />
    );
  },
});
