















































































































import { defineComponent, ref, computed } from '@vue/composition-api';
import { useTags } from '@/hooks/useTags';
import { LabelDropdown } from '@ui';
import { getAssetTypes } from '@/psychlab/meta';
import { CreateAssetConfig, CreateAssetHandler } from './ICreateAssetWizard';
import { useTranslate } from '@lang';

const nameAlias: any = {
  Graph: 'Blueprint',
};

export default defineComponent({
  components: {
    LabelDropdown,
  },
  setup(props, context) {
    const fn = ref<CreateAssetHandler | null>(null);
    const show = ref(false);
    const config = ref<CreateAssetConfig | null>(null);
    const modal = ref<any>(null);
    const input = ref<HTMLInputElement | null>(null);
    const name = ref('');

    const openEditor = ref(true);

    const selectedTags = ref<string[]>([]);

    const { translate } = useTranslate();

    const assetType = computed(() => {
      if (!config.value) {
        return null;
      }
      return getAssetTypes().find(a => a.name === config.value?.type);
    });

    const icon = computed(() => {
      return assetType.value?.icon;
    });

    const bgColor = computed(() => {
      return assetType.value?.color;
    });

    const activeTag = computed(() => {
      if (context.root.$route.name === 'view-tag') {
        return context.root.$route.params['tagId'];
      }
      if (context.root.$route.query['t']) {
        return context.root.$route.query['t'] as string;
      }
      if (context.root.$route.query['tag']) {
        return context.root.$route.query['tag'] as string;
      }
      return null;
    });

    const { sortedTags } = useTags();

    const tagOptions = computed(() => {
      return sortedTags.value.map(st => {
        return {
          id: st._id,
          ...st,
        };
      });
    });

    const variant = computed(() => {
      return 'dark';
    });

    const textVariant = computed(() => {
      return (
        (
          {
            light: 'dark',
          } as any
        )[variant.value] || 'light'
      );
    });

    const confirmText = computed(() => 'Create');

    const canSave = computed(() => {
      return name.value.length >= 2;
    });

    const title = computed(() => {
      if (!config.value) {
        return '';
      }
      const t = nameAlias[config.value.type] || config.value.type;
      return translate(`assets.action.create${t}`);
      // return `Create ${pascalToSentenceCase(nameAlias[config.value.type] || config.value.type)}`;
    });
    const placeholder = computed(() => config?.value?.placeholder || '');
    const showBackdrop = computed(() => config?.value?.showBackdrop || true);

    const ok = () => {
      if (fn.value) {
        fn.value({
          name: name.value,
          tags: selectedTags.value,
          openEditor: openEditor.value,
        });
      }
      (modal.value as any).hide();
    };

    const hide = () => {
      fn.value = null;
      show.value = false;
      name.value = '';
      config.value = null;
      selectedTags.value = [];
      openEditor.value = true;
    };

    const cancel = () => {
      if (!modal.value) {
        return;
      }
      modal.value.hide();
    };

    const open = async (c: CreateAssetConfig, f: CreateAssetHandler) => {
      config.value = c;
      fn.value = f;
      show.value = true;

      const t = nameAlias[c.type] || c.type;

      name.value = `${translate(`assets.label.new${t}`)}`;

      if (activeTag.value) {
        selectedTags.value.push(activeTag.value);
      }

      setTimeout(() => {
        if (input.value) {
          input.value.select();
        }
      }, 50);
    };

    const typeName = computed(() => assetType.value?.name);

    return {
      typeName,
      name,
      fn,
      show,
      selectedTags,
      canSave,
      variant,
      textVariant,
      modal,
      input,
      title,
      placeholder,
      showBackdrop,
      confirmText,
      ok,
      cancel,
      hide,
      open,
      openEditor,
      tagOptions,
      icon,
      bgColor,
      translate,
    };
  },
});
