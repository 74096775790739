import { defineComponent } from '@vue/composition-api';
import { useTranslate } from '@lang';
import { Icon } from '@ui';

enum Translations {
  Reset = 'action.reset',
  Modified = 'label.modified',
  Save = 'action.save',
}

export const SubmitBar = defineComponent({
  emits: ['reset', 'save'],
  props: {
    modified: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { translate } = useTranslate();
    return { translate };
  },
  render() {
    const rcls = `btn-${this.modified ? 'warning text-dark' : 'dark'}`;
    const scls = `btn-${this.modified ? 'success text-lightx' : 'dark'}`;

    return (
      <div class="d-flex flex-column">
        <div class="d-flex bg-dark">
          <button
            v-on:click={() => this.$emit('reset')}
            class={`btn text-white rounded-0 shadow-none d-flex font-weight-light ${rcls}`}
            disabled={!this.modified}
          >
            <span class="m-auto">
              <Icon name="skip-backward" /> {this.translate(Translations.Reset)}
            </span>
          </button>

          <div class="mx-auto text-warning font-weight-light d-flex flex-fill">
            <span class="m-auto">{this.modified ? this.translate(Translations.Modified) : ''}</span>
          </div>

          <button
            v-on:click={() => this.$emit('save')}
            class={`btn ml-auto text-white rounded-0 shadow-none d-flex font-weight-light ${scls}`}
            disabled={!this.modified}
            data-cy="save-form-btn"
          >
            <span class="m-auto">
              <Icon name="content-save" /> {this.translate(Translations.Save)}
            </span>
          </button>
        </div>

        <ChangeLine changed={this.modified} />
      </div>
    );
  },
});

const ChangeLine = defineComponent({
  props: {
    changed: Boolean,
  },
  render() {
    const cls = this.changed ? 'border-warning' : 'border-secondary';
    const style = 'border-bottom:3px solid transparent;';

    return <div style={style} class={cls} />;
  },
});
