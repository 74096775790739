type GetBuildInfo = {
  version: string;
};

export const getBuildInfo = (): GetBuildInfo => {
  const packageJson = require('./../../package.json');

  return {
    version: packageJson.version,
  };
};
