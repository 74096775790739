import { FormItemType } from '@psychlab/types/form';

export const getItemTypes = (): FormItemType[] => {
  return [
    FormItemType.Answer,
    FormItemType.Checkboxes,
    FormItemType.Dropdown,
    FormItemType.HTML,
    FormItemType.Information,
    FormItemType.LinearScale,
    FormItemType.MultipleChoice,
    FormItemType.Image,
    FormItemType.Video,
  ];
};
