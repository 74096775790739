// local
import { AssetType } from '../AssetType';

const Graph: AssetType = require('./Graph.json');
const Form: AssetType = require('./Form.json');
const Gallery: AssetType = require('./Gallery.json');
const CognitiveTest: AssetType = require('./CognitiveTest.json');
const External: AssetType = require('./External.json');

export { Graph, Form, Gallery, CognitiveTest, External };
