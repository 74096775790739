















































import { defineComponent, computed, ref, PropType } from '@vue/composition-api';
import { IFormTheme } from '../config';
import { getThemeDefaults } from '../config/getThemeDefaults';
import { default as ItemTitle } from './item-title.vue';
import { default as ItemBody } from './item-body.vue';
import { default as ItemDivider } from './item-divider.vue';
import { default as Hoverlay } from './hoverlay.vue';
import { IFormEditor } from '../hooks/useFormEditor';

import { useTranslate } from '@lang';
import { AbsBox, Icon } from '@ui';

import { Stripes, EmptyContentBox } from '../editor';

export default defineComponent({
  props: {
    title: {
      type: String,
      default: '',
    },
    body: {
      type: String,
      default: '',
    },
    theme: {
      type: Object as PropType<IFormTheme>,
      default: getThemeDefaults,
    },
    empty: {
      type: Boolean,
      default: false,
    },
    editor: {
      type: Object as PropType<IFormEditor>,
      required: false,
    },
    xp: {
      type: Number,
      default: 3,
    },
    yp: {
      type: Number,
      default: 3,
    },
    overflow: {
      type: Boolean,
      default: false,
    },
    hide: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ItemTitle,
    ItemBody,
    ItemDivider,
    Hoverlay,
    AbsBox,
    Icon,
    Stripes,
    EmptyContentBox,
  },
  setup(props) {
    const { translate } = useTranslate();

    const contentClass = computed(() => {
      return `px-${props.xp} py-${props.yp}`;
    });

    const boxStyle = computed(() => {
      const { theme, overflow } = props;

      let borderColor = theme['item.box.border.color'] || '#00000000';
      const bwidth = theme['item.box.border.width'] || 0;

      return {
        backgroundColor: theme['item.box.background.color'],
        color: theme['item.text.color'],
        borderRadius: `${theme['item.box.rounding'] || 0}em`,

        border: `${bwidth}px solid ${borderColor}`,
        overflow: overflow ? '' : 'hidden',
      };
    });

    const contentStyle = computed(() => {
      const { theme } = props;
      return {
        fontFamily: theme['item.text.fontFamily'],
        fontWeight: theme['item.text.fontWeight'],
      };
    });

    const boxClass = computed(() => {
      const shadow = `${props.theme['item.box.shadow'] ? 'shadow' : ''}`;
      const editor = `${props.editor ? 'editor' : ''}`;
      return `${shadow} ${editor}`;
    });

    const titleBoxStyle = computed(() => {
      const { theme } = props;
      return {
        backgroundColor: theme['item.title.background.color'] || '#ffffff00',
      };
    });

    const displayTitle = computed(() => {
      if (!props.editor) {
        return props.title;
      }
      return props.title || `(${translate('label.untitled').toLowerCase()})`;
    });

    return {
      contentClass,
      boxStyle,
      boxClass,
      titleBoxStyle,
      contentStyle,
      displayTitle,
      translate,
    };
  },
});
