










import { defineComponent } from '@vue/composition-api';
import { AbsBox } from '@ui';

export default defineComponent({
  emits: ['click'],
  props: {
    color: {
      type: String,
      default: '#00ffff13',
    },
  },
  components: {
    AbsBox,
  },
});
